import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {WebService} from "../web-services/web.service";

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  key: any = 'locker';
  currentUser: any;
  accountType: any;

  constructor(private router: Router, private service: WebService,) {
    this.key = 'locker';
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log("USERS");
    this.currentUser = localStorage.getItem('currentUser');
    this.accountType = localStorage.getItem('account_type');
    const path = ['transactions', 'accounts', 'teams', ''];
    if (this.currentUser && ((path.filter(e => '/' + e === state.url).length >= 1 && this.accountType === 'USER') || '/' === state.url || '/business' === state.url || '/facility' === state.url)) {
      const currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      if (!currentUserJson.lock) {
        const sub = this.service.getInt();
        sub.subscribe((user: any) => {
          if (user.status) {
            this.service.notificationsSubject.next(user);
          } else {
            this.callback(state, this.currentUser);
          }
        });
      } else {
        this.callback(state, this.currentUser);
      }

      return true;
    } else {
      // not logged in so redirect to login  page with the return url
      this.router.navigate(['/login']);
      return false;
    }
  }

  callback(state: RouterStateSnapshot, currentUser: any) {
    if (currentUser) {
      if (currentUser.status) {
        const currentUserJson = JSON.parse(currentUser);
        currentUserJson.message.msId = '';
        currentUserJson.lock = true;
        localStorage.setItem('currentUser', JSON.stringify(currentUserJson));
        this.router.navigate(['/lock'], {}/*{queryParams: {returnUrl: state.url}}*/);
      }
    } /*else {
                this.router.navigate(['/logout'], {}/!*{queryParams: {returnUrl: state.url}}*!/);
            }*/
  }
}
