<ng-container *ngIf="!loading">
  <h3 mat-dialog-title style="margin-bottom: 1px !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity));font-weight: 400;">Create new ticket</h3>
  <br/>
  <div mat-dialog-content>
  <form>
    <div class="flex flex-row">
      <div class="w-full">
        <mat-form-field appearance="fill">
          <mat-label>Search customer by phone number</mat-label>
          <input
             type="text"
             placeholder="Pick one"
             aria-label="Number"
             minlength="10"
             required
             maxlength="12"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto"
             />
          <mat-icon matSuffix *ngIf="!formSpinner">search</mat-icon>

          <mat-progress-spinner 
          *ngIf="formSpinner"
          matSuffix
          mode="indeterminate" 
          diameter="16"></mat-progress-spinner>

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
            (click)="formSpinner = false"
            *ngFor="let option of options" [value]="option['phone']"  class="tiny-letter-spacing"
            >
              {{option['fullName']}}
            </mat-option>
          </mat-autocomplete>
  
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- for transaction ID -->

   
  
    <form class="flex flex-col" [formGroup]="createTicketGroup">
      <small style="color:red;padding-top:5px;padding-bottom: 5px;" *ngIf="notFound">
        <mat-icon style="font-size: 15px !important;">info</mat-icon>NO ASSOCIATED ACCOUNT FOUN, ENTER MANUALLY</small>
      
        
        <div class="flex flex-row" *ngIf="createTicketGroup.get('customerName').value.length >= 1 || notFound">
        
        <div class="w-full">
        
          <mat-form-field appearance="fill">
            <mat-label [floatLabel]="'always'">Customer name</mat-label>
            <input matInput  formControlName="customerName"/>

          </mat-form-field>
        </div>
      </div>
  
      
   <div class="flex flex-row">
                <div  [className]="!checkCategoryType('OTHER_ISSUES') ? 'w-49': 'w-full'">
                  <mat-form-field appearance="fill">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category">
                      <mat-option *ngFor="let category of categories" [value]="category">
                        {{category}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="ml-15 w-49" *ngIf="!checkCategoryType('OTHER_ISSUES')">
                  <mat-form-field appearance="fill">
                    <mat-label>Tasks to resolve</mat-label>
                    <mat-select formControlName="resolveTask">
                      <mat-option *ngFor="let task of tasks" [value]="task">
                        {{task}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
     </div>

      <div class="w-full" *ngIf="checkCategoryType('TRANSACTION_ISSUES')">
        <small style="color:red;padding-top:5px;padding-bottom: 5px;" *ngIf="transIDNotFound">
          <mat-icon style="font-size: 15px !important;">info</mat-icon>{{message}}</small>

        <mat-form-field appearance="fill">
          <mat-label>Search transaction ID</mat-label>
          <input
                  type="text"
                  placeholder="Pick one"
                  aria-label="Number"
                  minlength="8"
                  required
                  matInput
                  [formControl]="transactionControl"
                  [matAutocomplete]="auto"
          />
          <mat-icon matSuffix *ngIf="!transFormSpinner">search</mat-icon>

          <mat-progress-spinner
                  *ngIf="transFormSpinner"
                  matSuffix
                  mode="indeterminate"
                  diameter="16"></mat-progress-spinner>
          <mat-autocomplete
                  autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
                    (click)="transFormSpinner = false"
                    [value]="transactionOptions"
            >
              {{trimTransactionID(this.transactionOptions)}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>

      </div>
  
            <div class="w-full">
              <mat-form-field appearance="fill">
                <textarea matInput placeholder="Explain customer's complaint e.g Account balance was lower than expected Amount" 
                formControlName="complaint"></textarea>
              </mat-form-field>
            </div>
  
  
    </form>

     
  
  </div>
  
  
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="rounded-full">cancel</button>
    <button mat-raised-button 
    (click)="createTicket()"
    class="btn-out cta-action"
    cdkFocusInitial >CREATE TICKET</button>
  </mat-dialog-actions>
</ng-container>

<div class="py-8" *ngIf="loading">
  <mat-progress-bar></mat-progress-bar>
  <p class="text-gray-800 text-center text-base">Processing please wait</p>
</div>
