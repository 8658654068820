import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ThemePalette} from '@angular/material/core';
import {WebService} from 'src/app/web-services/web.service';
import {Router} from '@angular/router';
import {FilteringPipe} from 'src/app/pipes/filtering.pipe';
import {ConnectionService} from 'ng-connection-service';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {MatDialog} from '@angular/material/dialog';
import {ResolvedialogComponent} from '../../sharables/resolvedialog/resolvedialog/resolvedialog.component';
import {HttpClient} from '@angular/common/http';
import {AllTickets, Complaints, TicketQueryOperation} from 'src/app/models/models';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TicketquerydialogComponent} from '../../sharables/ticketquerydialog/ticketquerydialog/ticketquerydialog.component';
import {Subscription} from 'rxjs';
import {CommentsComponent} from '../../sharables/commentsdialog/comments/comments.component';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, retry, retryWhen, startWith, switchMap} from 'rxjs/operators';
import {delayedRetry} from 'src/app/sharables/retry-utility';


@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class TicketsComponent implements OnInit, OnDestroy {
    currentUser: any;
    accountId: any;
    isConnect: Boolean = true;
    userRole: "ADMIN" | "USER";
    networkMode: string = 'online';
    accountType: any;
    status: string;
    links = ['ALL', 'TRANSFERRED', 'PENDING', 'RESOLVED'];
    // links = ['ALL', 'OVERDUE', 'RECEIVED', 'TRANSFERRED', 'PENDING', 'RESOLVED'];
    activeLink = this.links[0];
    background: ThemePalette = 'primary';
    isLoadingResults = true;

    constructor(
        private route: Router,
        private transFilter: FilteringPipe,
        private svc: WebService,
        private dbService: NgxIndexedDBService,
        private connectionService: ConnectionService,
        private dialog: MatDialog,
        private http: HttpClient,
        private _fb: FormBuilder,
    ) {
    }


    ngOnInit(): void {
        this.connectionStatus(this.connectionService);
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.accountType = this.currentUser['message']['account'];
        this.accountId = this.currentUser['message']['_id'];
    }

    ngOnDestroy(): void {
    }

    connectionStatus(connectionService: ConnectionService) {
        connectionService.monitor().subscribe((isConnected: boolean) => {
            this.isConnect = isConnected;
            if (isConnected) {
                this.status = 'ONLINE';
                this.svc.notify('info', "You are now back online");
            } else {
                this.status = 'OFFLINE';
                this.svc.notify('warning', "You are offline, Please check your connection");
            }
        });
        this.status = this.isConnect ? 'ONLINE' : 'OFFLINE';
    }

    onTabChanged(event: any) {
        this.svc.notifications.next(event);
    }

}
