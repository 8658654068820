import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ThemePalette} from '@angular/material/core';
import {WebService} from 'src/app/web-services/web.service';
import {Router} from '@angular/router';
import {FilteringPipe} from 'src/app/pipes/filtering.pipe';
import {ConnectionService} from 'ng-connection-service';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {MatDialog} from '@angular/material/dialog';
import {ResolvedialogComponent} from '../../resolvedialog/resolvedialog/resolvedialog.component';
import {HttpClient} from '@angular/common/http';
import {AllTickets, Complaints, TicketQueryOperation} from 'src/app/models/models';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TicketquerydialogComponent} from '../../ticketquerydialog/ticketquerydialog/ticketquerydialog.component';
import {Subscription} from 'rxjs';
import {CommentsComponent} from '../../commentsdialog/comments/comments.component';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, retry, startWith, switchMap} from 'rxjs/operators';
import {delayedRetry} from '../../retry-utility';
import {ReferdialogComponent} from "../../../pages/referdialog/referdialog.component";

@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class DatatableComponent implements OnInit {
    categoryMenuGroup: FormGroup;
    expandedElement: AllTickets | null;
    currentUser: any;
    isConnect: Boolean = true;
    accountId: any;
    userRole: "ADMIN" | "USER";
    networkMode: string = 'online';
    userProfile: { name: string; nin: string; _id: string, uname: string, position: string, co: string };
    accountType: any;
    fetchedTickets: any[] = [];
    usertransactions: any;
    status: string;
    links = ['ALL', 'RECEIVED', 'RESOLVED', 'REFERRED'];
    filterOptions = ["ALL", "MY RECENT ASSIGNMENTS", "CATEGORY", "STATUS", "OUT FLOWS", "IN FLOWS", "DATE",  "CUSTOMER NO", "COMPLAINT ID"];
    activeLink = this.links[0];
    background: ThemePalette = 'primary';
    displayedColumns: string[] = ['complaintId', 'customerName', 'task', 'isClosed', 'who', 'ctype', 'cta'];
    displayedColumnsAtt: string[] = [ 'by', 'state', 'from', 'to', 'when', 'Reason'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    queryBy: string;
    querysubscription: Subscription;
    openedDialogs: boolean = false;
    paginationPage: number = 0;
    operationType: Complaints;
    pageSize: number = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    // MatPaginator Output
    pageEvent: PageEvent;

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    resultsLength = 0;
    isLoadingResults = false;
    isRateLimitReached = false;

    constructor(
        private route: Router,
        private transFilter: FilteringPipe,
        private svc: WebService,
        private dbService: NgxIndexedDBService,
        private connectionService: ConnectionService,
        private dialog: MatDialog,
        private http: HttpClient,
        private _fb: FormBuilder,
    ) {
        this.svc.notifications.subscribe((e: any) => this.reloadUi(e) );
    }


    ngOnInit(): void {
        this.connectionStatus(this.connectionService);
        this.getUserProfile();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.accountType = this.currentUser['message']['account'];
        this.accountId = this.currentUser['message']['_id'];

        // initiate category menu
        this.categoryMenuGroup = this._fb.group({category: ['SELECT CATEGORIES']});

        // this.operationType = {
        //     accountId: this.accountId,
        //     task: "GET_COMPLAINT",
        //     queryBy: this.activeLink
        // };

        this.operationType = {
            accountId: this.accountId,
            task: "GET_COMPLAINT",
            queryBy: 'ALL',
            // category: 'TRANSACTION_ISSUES'
        }


        // setTimeout(() => {
            this.fetchTickets(this.operationType);
        // }, 30);
        // this.userRole = this.accountType;
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this.accountId = this.currentUser.accountId;
        // if (this.networkMode === 'online') {
        //     this.mergeDatabases();
        //     console.log('am online');
        // }
        // this.svc.refreshNeeded$.subscribe(() => {
        //     this.operationType.queryBy = this.activeLink;
        //     this.fetchTickets(this.operationType);
        // });
        //
        // this.categoryMenuGroup.valueChanges.subscribe((value: { category: string }) => {
        //     this.filterQuery(value.category);
        // });
        // this.querysubscription = this.svc.apiCall(null).subscribe(data => {
        //     if (data) {
        //         this.filterBasedOnSubjectQuery(data);
        //         this.dataSource = new MatTableDataSource(this.fetchedTickets);
        //     } else {
        //         this.fetchedTickets = [];
        //     }
        // });
    }

    getUserProfile(): void {
        try {
            const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
            if (!currentUser) {
                throw new Error('User does not exist');
            }

            this.userProfile = {
                _id: JSON.parse(localStorage.getItem('account_id')),
                name: currentUser['message']['firstName'] + ' ' + currentUser['message']['lastName'],
                nin: currentUser['message']['nin'],
                uname: currentUser['message']['customerCare']['staffId'],
                position: currentUser['message']['customerCare']['position'],
                co: currentUser['message']['customerCare']['company']
            };
            console.log('my hoe bitch', this.userProfile)
        } catch (e) {
            console.log(e);
        }

    }


    reloadUi(e: any): void {
        console.log("FIRE", e);
        this.onTabChanged(e);
    }

    ngOnDestroy(): void {
        this.fetchedTickets = null;
        this.fetchedTickets = null;
        // this.ticketService.clearMessages();
    }


    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }


    openDialog(payload: AllTickets): void {
        const dialogRef = this.dialog.open(ResolvedialogComponent, {
            width: '66%',
            data: payload
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    passATT(data: any) {
        let dataSource1: MatTableDataSource<any> = new MatTableDataSource<any>();
        dataSource1.data = data;
        return dataSource1;
    }


    getDisplayText(data: any) {
        let x = "0";

        switch (data) {
            case "MOVED":
                data = "REASSIGNED";
                break;
            default:
                data;
        }
        return  data;
    }

    connectionStatus(connectionService: ConnectionService) {
        connectionService.monitor().subscribe((isConnected: boolean) => {
            this.isConnect = isConnected;
            if (isConnected) {
                this.status = 'ONLINE';
                this.svc.notify('info', "You are now back online");
            } else {
                this.status = 'OFFLINE';
                this.svc.notify('warning', "You are offline, Please check your connection");
            }
        });
        this.status = this.isConnect ? 'ONLINE' : 'OFFLINE';
    }


    addComments(payload: AllTickets): void {
        const dialogRef = this.dialog.open(CommentsComponent, {
            width: '66%',
            data: payload
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    addRefer(payload: AllTickets): void {
        const dialogRef = this.dialog.open(ReferdialogComponent, {
            width: '66%',
            data: payload
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    //accountId: String, task: Task.TASK, customerNo: Option[String], customerName: Option[String], transactionId: Option[String], complaint: Option[String], resolveTask: Option[RESOLVE_ISSUES], complaintId: Option[String], time: Option[TimePoint], comment: Option[List[Comment]] = None, attachment: Option[List[Attachment]] = None, queryBy: Option[QUERY_BY] = None, filterTime: Option[Time] = None, pagination: Option[Page] = None, category: Option[Complaint_Category.COMPLAINT_CATEGORY], isClosed: Option[Closed],customerCare: Option[CustomerCare]

    filterBasedOnSubjectQuery(payload: TicketQueryOperation): void {
        switch (payload.operation) {
            case 'TIME':
                this.openedDialogs = true;
                this.dialog.closeAll();
                this.openTickeQueryDialog('TIME', true);
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'TIME',
                    filterTime: {
                        startTime: payload.startTime,
                        endTime: payload.endTime
                    }
                }
                this.fetchTickets(this.operationType);
                break;
            case 'STATUS':
                this.openedDialogs = true;
                this.dialog.closeAll();
                this.openTickeQueryDialog('STATUS', true);
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'STATUS',
                    isClosed: {
                        isClosedStatus: payload.isClosedStatus
                    }
                };
                this.fetchTickets(this.operationType);
                break;
            case 'CATEGORY':
                this.openedDialogs = true;
                this.dialog.closeAll();
                this.openTickeQueryDialog('CATEGORY', true);
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'CATEGORY',
                    category: 'TRANSACTION_ISSUES'
                };
                this.fetchTickets(this.operationType);
                break;
            case 'CATEGORY_TIME':
                this.openedDialogs = true;
                this.dialog.closeAll();
                this.openTickeQueryDialog('CATEGORY_TIME', true);
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'CATEGORY_TIME',
                    category: 'TRANSACTION_ISSUES',
                    filterTime: {
                        startTime: payload.startTime,
                        endTime: payload.endTime
                    }
                }
                this.fetchTickets(this.operationType);
                break;
            case 'STATUS_TIME':
                this.openedDialogs = true;
                this.dialog.closeAll();
                this.openTickeQueryDialog('STATUS_TIME', true);
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'STATUS_TIME',
                    filterTime: {
                        startTime: payload.startTime,
                        endTime: payload.endTime
                    },
                    isClosed: {
                        isClosedStatus: payload.isClosedStatus
                    }
                };
                this.fetchTickets(this.operationType);
                break;
            case 'CATEGORY_STATUS_TIME':
                this.openedDialogs = true;
                this.dialog.closeAll();
                this.openTickeQueryDialog('CATEGORY_STATUS_TIME', true);
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'CATEGORY_STATUS_TIME',
                    category: 'TRANSACTION_ISSUES',
                    filterTime: {
                        startTime: payload.startTime,
                        endTime: payload.endTime
                    },
                    isClosed: {
                        isClosedStatus: payload.isClosedStatus
                    }
                };
                this.fetchTickets(this.operationType);
                break;
            default:
                null;
        }
    }

    filterQuery(query: string): void {
        switch (query) {
            case 'ALL':
                this.operationType = {
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: 'ALL'
                };
                this.fetchTickets(this.operationType);
                break;
            case 'CATEGORY':
                this.openTickeQueryDialog('CATEGORY');
                break;
            case 'STATUS':
                this.openTickeQueryDialog('STATUS');
                break;
            case 'BY_STATUS':
                this.openTickeQueryDialog('BY_STATUS');
                break;
            case 'TIME':
                this.openTickeQueryDialog('TIME');
                break;
            case 'CATEGORY_TIME':
                this.openTickeQueryDialog('CATEGORY_TIME');
                break;
            case 'CATEGORY_STATUS_TIME':
                this.openTickeQueryDialog('CATEGORY_STATUS_TIME');
                break;
            case 'STATUS_TIME':
                this.openTickeQueryDialog('STATUS_TIME');
                break;
            default:
                this.fetchTickets({
                    accountId: this.accountId,
                    task: "GET_COMPLAINT",
                    queryBy: "ALL"
                });
        }
    }

    openTickeQueryDialog(operation: string, isLoading: boolean = false): void {
        const dialogRef = this.dialog.open(TicketquerydialogComponent, {
            width: '46%',
            height: 'auto',
            data: {operation, isLoading}
        });
    }

    get closeAnyOpenedDialogs(): void {
        if (this.openedDialogs) {
            this.dialog.closeAll();
            this.openedDialogs = false;
            return null;
        }// close any dialog if any
    }

    fetchTickets(query: Complaints): void {
        const currentUser = `${JSON.parse(localStorage.getItem('account_id'))}`;
        query['accountId'] = 'FRI:' + currentUser + '@clinicpesa.public.user.sp1/SP';
        console.log("LOGGGG.....", query);
        this.svc.apiCall(query).subscribe((data: any)  => { console.log("LOGGGG.....",data);
            if (data['message'].length > 0) {
                this.fetchedTickets = data['message'];
                this.dataSource.data = this.fetchedTickets;
            }else {

            }

        })
    }

   /* fetchTickets(query: Complaints): void {
        const currentUser = `${JSON.parse(localStorage.getItem('account_id'))}`;
        query['accountId'] = currentUser;
        if (this.networkMode === 'online') {
            this.svc.apiCall(query).map(e =>{ console.log("RES.....",e);e;})
            this.paginator.page.pipe(
                startWith({}),
                switchMap(() => {

                    // this.isLoadingResults = true;
                    const paginate = {max: 6, page: this.paginationPage};
                    query['pagination'] = paginate;
                    console.log("TICKET...", query)
                    return this.svc.apiCall(query).map(e =>{ console.log("RES",e);e;})
                        .pipe(retry(5), catchError(() => observableOf(null)));
                }),
                map(data => {
                    console.log("DATA", data);
                    // this.isLoadingResults = false;
                    this.isRateLimitReached = data === null;
                    if (data === null) {
                        return [];
                    }

                    this.resultsLength = data['message'].length;
                    console.log(data);
                    return data['message'];
                })
            ).subscribe((data: any) => {
                // this.isLoadingResults = false;
                if (data.length > 0) {
                    // this.fetchedTickets = data;
                    this.fetchedTickets = data;
                    this.dataSource.data = this.fetchedTickets;
                    this.closeAnyOpenedDialogs;
                } else {
                    this.closeAnyOpenedDialogs;
                    this.dbService.getAll('Tickets').subscribe((dat: any[]) => {
                        console.log('Getting all tickets', dat);
                        this.fetchedTickets = [{
                            ticketName: " Chris's Ticket",
                            phone: "256773294150",
                            message: "Chris",
                            category: "transaction",
                            referTo: {recipient: 'Chris'},
                            ticketStatus: 'open',
                            date: 1620457321
                        }];
                        console.log(this.fetchedTickets);
                    });
                }
            }, error => {
                console.log('am error now', error);
                this.closeAnyOpenedDialogs;
                this.dbService.getAll('Tickets').subscribe((data) => {
                    // this.fetchedTickets = data.reverse();
                    console.log('data bitch', data)
                    this.fetchedTickets = [{
                        ticketName: " Chris's Ticket",
                        phone: "256773294150",
                        message: "Chris",
                        category: "transaction",
                        ticketStatus: 'open',
                        referTo: {recipient: 'Chris'},
                        date: 1620457321
                    }];
                    console.log(this.fetchedTickets);
                });
            });
        }
    }*/

    filterbyPhone(number: any) {
        this.svc.apiCall(number).subscribe((data: any) => {
            if (data.status) {
                this.usertransactions = data.data;
            }
        });
    }


    mergeDatabases() {

        this.dbService.getAll('Tickets').subscribe((tickets) => {
            tickets.forEach((ticket: any, index: number) => {
                console.log(index);
                this.dbService.clear('Tickets');
                if (ticket.synced === false) {
                    console.log(ticket, index);
                    this.svc.apiCall(ticket).subscribe((data: any) => {
                        if (data.status) {
                            this.svc.refreshNeeded$.subscribe(() => {
                                this.fetchTickets({
                                    accountId: this.accountId,
                                    task: "GET_COMPLAINT",
                                    queryBy: this.activeLink
                                });

                            });
                            ticket.synced = true;
                            this.dbService.update('Tickets', ticket).subscribe((data) => {
                                console.log(data);
                            });
                        }
                    });
                }


                console.log(tickets);
            });

            // console.log("merging.....")
            // this.getOfflineData()
            // this.offlinedata.forEach((el: any, index: number) => {
            //   if (el ===this.fetchedTickets[index]) {
            //     this.ticketService.create(el).subscribe((data: any) => {
            //       if (data.status) {
            //         //  alert reason and refresh the ticket;
            //         console.log(data.message, "here i am");
            //         this.svc.refreshNeeded$.subscribe(()=>{
            //           this.fetchTickets();

            //        })
            //       } else {
            //         el["synced"] = false
            //         console.log(el["synced"])
            //         this.dbService
            //         .add('Tickets', el
            //         )
            //         .subscribe((key) => {
            //           console.log('key: ', key);
            //         });
            //       }
            //     }, error => {

            //       this.dbService
            // .add('Tickets', el)
            // .subscribe((key) => {
            //   console.log('key: ', key);
            // });


            //                 });

            //     this.dbService. delete('Tickets', el.id);
            //   }
            // });

        });
    }

    onTabChanged(event: any) {
        this.activeLink = event;
        switch (this.activeLink) {
            case 'ALL':// RECEIVED
                // this.fetchedTickets = this.fetchedTicketsCopy;
                // let l_operationType = {
                //     accountId: this.accountId,
                //     task: "GET_COMPLAINT",
                //     queryBy: 'ALL',
                //     // category: 'TRANSACTION_ISSUES'
                // }
                // this.dataSource = new MatTableDataSource(this.fetchedTickets);
                this.fetchTickets(this.operationType);
                break;
            case 'TRANSFERRED':
                // const failedTickets = this.fetchedTicketsCopy.filter((ticket) => ticket['isClosed']['isClosedStatus'] === false);
                // this.fetchedTickets = failedTickets;
                // let l_operationType = {
                //     accountId: this.accountId,
                //     task: "GET_COMPLAINT",
                //     queryBy: 'ALL',
                //     // category: 'TRANSACTION_ISSUES'
                // }
                // this.dataSource = new MatTableDataSource(this.fetchedTickets);
                this.fetchTickets(this.operationType);
                break;
            case 'PENDING':
                // const closedTickets = this.fetchedTicketsCopy.filter((ticket) => ticket['isClosed']['isClosedStatus'] === true);
                // this.fetchedTickets = closedTickets;
                // this.dataSource = new MatTableDataSource(this.fetchedTickets);
                this.fetchTickets(this.operationType);
                break;
            case 'RESOLVED':
                // const closedTickets = this.fetchedTicketsCopy.filter((ticket) => ticket['isClosed']['isClosedStatus'] === true);
                // this.fetchedTickets = closedTickets;
                this.fetchTickets(this.operationType);
                // this.dataSource = new MatTableDataSource(this.fetchedTickets);
                break;
        }
    }


}
