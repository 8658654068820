import {Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TeamsdialogComponent} from '../teamsdialog/teamsdialog.component';
import {catchError, map, retry, startWith, switchMap, debounceTime, tap, finalize} from 'rxjs/operators';
import {Teams} from 'src/app/models/models';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {PhoneNumber, WebService} from 'src/app/web-services/web.service';

@Component({
    selector: 'app-teams',
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class TeamsComponent implements OnInit {
    displayedColumns: string[] = ["phone","names", "uname", "who", "timestamp", "cta"];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    categoryMenuGroup: FormGroup;
    filterOptions: string[] = ["ALL_TWO", "CLINICPESA", "MTN"];
    userProfile: { name: string; nin: string; _id: string, uname: string, position: string, co: string };
    expandedElement: any;
    isLoadingResults: boolean = true;
    isRateLimitReached: boolean = false;
    resultsLength: number = 0;
    fetchedTeams: any[] = [];
    fetchedTicketsCopy: any[] = [];
    _query: Teams = {
        "task": "GET_CUSTOMER_CARE",
        "customerName": "ALL_TWO",
        "accountId": "",
        "customerNo": "",
        "pagination": {
            page: 0,
            max: 100
        }
    };

    searchTeamContactCtrl = new FormControl();
    filteredNumbers: any;
    isLoading = false;
    errorMsg: string;

    constructor(private dialog: MatDialog, private _fb: FormBuilder,
                private dbService: NgxIndexedDBService, private svc: WebService) {

    }

    ngOnInit(): void {
        this.getUserProfile();
        this._query.customerName = this.userProfile.co;
        this._query.accountId = `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`;
        this._query.customerNo = `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`;
        this.fetchTeams();

        this.categoryMenuGroup = this._fb.group({
            category: ['']
        });

        this.categoryMenuGroup.get('category').valueChanges.subscribe(term => {
            this._query.customerName = term;
            this.fetchTeams();
        });

        this.searchTeamContactCtrl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.errorMsg = "";
                    this.filteredNumbers = [];
                    this.isLoading = true;
                }),
                switchMap(value => {
                        let phone = new PhoneNumber();
                        phone.line = value;
                        this._query.customerName = `${phone.e164}`;
                        return this.svc.apiCall(this._query)
                            .pipe(
                                finalize(() => {
                                    this.isLoading = false
                                }),
                            )
                    }
                )
            )
            .subscribe((data: any) => {
                if (data['message'] == undefined) {
                    this.errorMsg = data['Error'];
                    this.filteredNumbers = [];
                } else {
                    this.errorMsg = "";
                    this.filteredNumbers = data['message'];

                    this.resultsLength = this.filteredNumbers.length;
                    this.fetchedTeams = this.filteredNumbers;
                    this.fetchedTicketsCopy = this.filteredNumbers;
                    this.dataSource.data = this.fetchedTeams;
                    console.log('searched items', this.fetchedTeams);

                }

            }, err => {
                this.fetchedTeams = [];
                this.isLoadingResults = false;
                this.isRateLimitReached = true;
                this.svc.handleError(err);
            });

    }

    ngAfterViewInit() {
        this.fetchTeams();
        this.dataSource.paginator = this.paginator;

    }

    getUserProfile(): void {
        try {
            const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
            if (!currentUser) {
                throw new Error('User does not exist');
            }

            this.userProfile = {
                _id: JSON.parse(localStorage.getItem('account_id')),
                name: currentUser['message']['firstName'] + ' ' + currentUser['message']['lastName'],
                nin: currentUser['message']['nin'],
                uname: currentUser['message']['customerCare']['staffId'],
                position: currentUser['message']['customerCare']['position'],
                co: currentUser['message']['customerCare']['company']
            };
            console.log('my hoe bitch', this.userProfile)
        } catch (e) {
            console.log(e);
        }

    }


    openDialog(): void {
        const dialogRef = this.dialog.open(TeamsdialogComponent, {
            width: '66%'
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    fetchTeams(): void {
        console.log("query", this._query)
        this.svc.apiCall(this._query).subscribe((data: any) => {
            console.log("data", this._query, data)
            if (data.status) {
                this.isLoadingResults = false;
                console.log(data);
                this.isRateLimitReached = !!data;
                this.resultsLength = data['message'].length;
                if (this.resultsLength > 0) {
                    this.fetchedTeams = data['message'];
                    this.fetchedTicketsCopy = data['message'];
                    this.dataSource.data = this.fetchedTeams;
                } else {
                    console.log('elsee block')
                }
            } else {
            }
        }, error => {
            this.fetchedTeams = [];
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            this.svc.handleError(error);
        });


       /* this.paginator.page.pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                const paginate = {max: 6, page: this.paginator.pageIndex};
                this._query['pagination'] = paginate;
                return this.svc.apiCall(this._query)
                    .pipe(retry(5), catchError(() => observableOf(null)));
            }),
            map((data: any) => {
                this.isLoadingResults = false;
                console.log(data);
                this.isRateLimitReached = data === null;
                if (data === null) {
                    return [];
                }
                this.resultsLength = data['message'].length;
                return data['message'];
            })
        ).subscribe((data: any) => {
            if (data.length > 0) {
                this.fetchedTeams = data;
                this.fetchedTicketsCopy = data;
                this.dataSource.data = this.fetchedTeams;
            } else {
                console.log('elsee block')
                this.dbService.getAll('Tickets').subscribe((dat: any[]) => {
                    this.fetchedTeams = [];
                });
            }
        }, error => {
            //console.log('am error now', error);
            this.fetchedTeams = [];
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            this.svc.handleError(error);
            this.dbService.getAll('Tickets').subscribe((data) => {
                console.log('data bitch', data);
            });
        });*/
    }


}


function observableOf(arg0: null): any {
    throw new Error('Function not implemented.');
}

