import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { WebService} from 'src/app/web-services/web.service';

@Component({
    selector: 'set-username',
    templateUrl: './set-username.component.html',
    styleUrls: ['./set-username.component.scss']
})
export class SetUsernameComponent implements OnInit {
    u_name_ctl = new FormControl('');
    loading: boolean = false;
    message: string = "...";
    customerCare: string = "";
    formateAccIDNumber: string = "";
    formSpinner: boolean = false;
    availability: boolean = false;
    isUnameResp: boolean = false;


    constructor(private _fb: FormBuilder, private svc: WebService, private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.getUserProfile();


        // auto-complete for searching customer number
        this.u_name_ctl.valueChanges.subscribe(event => {
            let size = `${event}`.length;
            if (size === 0) {
                this.formSpinner = false;
                this.availability = false;
            }
            if (size >= 3) {
                this.formSpinner = true;
                this.svc.apiCall(
                    {
                        "task": "CHECK_UN",
                        "customerNo": `FRI:${this.formateAccIDNumber}@clinicpesa.public.user.sp1/SP`,
                        "accountId": `FRI:${this.formateAccIDNumber}@clinicpesa.public.user.sp1/SP`,
                        "customerCare": {
                            "staffId": event.toString()
                        }
                    }).subscribe((data: any) => {
                    console.log("DATA", this.formateAccIDNumber, data)
                    this.isUnameResp = true;
                    if (data['status']) {
                        this.availability = data['availability'];
                    }
                    this.formSpinner = false;
                });
            } else {
                this.formSpinner = false;
                this.isUnameResp = true;
            }
        });

    }

    getUserProfile(): void {
        try {
            const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
            if (!currentUser) {
                throw new Error('User does not exist');
            }
            this.customerCare = currentUser['message']['customerCare'];
            this.formateAccIDNumber = currentUser['message']['phone'];
        } catch (e) {
            console.log(e);
        }
    }

    createUName(): void {
        this.loading = true;
        this.svc.apiCall({
            "task":"CREATE_UN",
            "customerNo": `FRI:${this.formateAccIDNumber}@clinicpesa.public.user.sp1/SP`,
            "accountId": `FRI:${this.formateAccIDNumber}@clinicpesa.public.user.sp1/SP`,
            "customerCare": {
                "staffId": this.u_name_ctl.value
            }
        }).subscribe((data: any) => {
            // console.log("DDF", data)
            if (data.status) {
                this.loading = false;
                this.svc.notifications.next({ 'un' : this.u_name_ctl.value, action: 'UNUPDATE'});
                this.svc.notify('success', `${this.u_name_ctl.value} successfully set as your username.`, 2);
                this.dialog.closeAll();
            } else {
                this.loading = false;
                this.svc.notify('error', data.message, 2);
            }
        }, error => {
            this.loading = false;
            this.svc.handleError(error);
        });
    }

}

