<div class="mat-elevation-z8 m-t-10 bg-white">
    <br/>
    <div class="flex flex-row px-5">

        <div class="flex flex-row " style="flex-basis: 50%;">
            <div class="flex-1">
                <mat-form-field class="w-80" appearance="fill" [hideRequiredMarker]="true" [floatLabel]="'auto'">
                    <mat-label>Phone</mat-label>
                    <input matInput [matAutocomplete]="auto" placeholder="078xxxxxxx" [formControl]="searchCustomerContactCtrl" required minlength="10" maxlength="12"/>
                    <mat-error>Phone number is required*</mat-error>
                    <mat-progress-spinner matSuffix diameter="14" *ngIf="isLoadingNumbers" mode="indeterminate"></mat-progress-spinner>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngIf="isLoadingNumbers" class="is-loading">Loading...</mat-option>
                        <ng-container *ngIf="!isLoadingNumbers">
                            <mat-option *ngFor="let user_account of filteredNumbers" [value]="user_account.phone">
                                <span><b>{{user_account.phone ? user_account.lastName + " " + user_account.firstName : "Sorry, Account not found...."}}</b></span>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>

                    <ng-container *ngIf="errorMsgNumbers;">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option>Whoops* {{errorMsgNumbers}}</mat-option>
                        </mat-autocomplete>
                    </ng-container>
                    <mat-hint>Enter only 10 digits e.g 078xxxxxxx</mat-hint>
                </mat-form-field>
            </div>


            <div class="flex-1">
                <mat-form-field class="w-80" appearance="fill">
                    <mat-label>Transaction ID</mat-label>
                    <input matInput placeholder="Ex. CCfbc" [formControl]="searchTransactionIdContactCtrl">
                    <mat-progress-spinner matSuffix diameter="14" *ngIf="isLoadingTransaction" mode="indeterminate"></mat-progress-spinner>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngIf="isLoadingTransaction" class="is-loading">Loading...</mat-option>
                        <ng-container *ngIf="!isLoadingTransaction">
                            <mat-option *ngFor="let user_account of filteredTransactions" [value]="user_account.t_id">
                                <span>{{user_account.t_id}}</span>
                            </mat-option>
                        </ng-container>

                        <ng-container *ngIf="errorMsgTransaction">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option>Whoops* {{errorMsgTransaction}}</mat-option>
                            </mat-autocomplete>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>


        <form [formGroup]="transactionsGroup" style="flex-basis: 50%;" class="flex flex-row">

            <div class="flex-1">
                <mat-form-field appearance="fill" class="w-80">
                    <mat-label>Select category</mat-label>
                    <mat-select formControlName="category">

                        <mat-option *ngFor="let option of transactionOptions" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex-1">
                <mat-form-field appearance="fill">
                    <mat-label>Select date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" formControlName="startDate">
                        <input matEndDate placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="flex py-3" style="justify-content: center;">
        <button (click)="resetTransaction()" mat-stroked-button color="primary" class="rounded-full mr-3 tiny-letter-spacing">
            <mat-icon>refresh</mat-icon> RESET
        </button>
        <button mat-flat-button color="primary" (click)="searchTransaction()" class="rounded-full text-white tiny-letter-spacing">SEARCH
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <mat-divider class="w-full"></mat-divider>
    <!-- table -->
    <div class="bg-white relative" [hidden]="resultsLength === 0">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="accountNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">
                    <span class="text-gray-900">Transaction Type</span>
                </th>
                <td mat-cell *matCellDef="let row" class="w-25">
                    <span class="text-gray-800">{{ row.transType }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25 text-center"
                    style="padding-left: 31px !important">
                    <span class="text-gray-900">Amount</span>
                </th>
                <td mat-cell *matCellDef="let row" class="w-25">
                    <span class="text-gray-800" style="margin-left:18%;"><span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{row.currency}} </small></span>{{commaValues(row.amount)}} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="timeStamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">
                    <span class="text-gray-900">Time</span>
                </th>
                <td mat-cell *matCellDef="let row" class="w-25">
                    <span class="text-gray-800">{{ row.timeStamp | date }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">
                    <span class="text-gray-900">Status</span>
                </th>
                <td mat-cell *matCellDef="let row" class="w-25">
                  <span class="text-gray-800">
                    {{ row.status }}
                  </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">
                    <span class="text-gray-900">More details</span>
                </th>
                <td mat-cell *matCellDef="let row" class="w-25">
                    <span class="text-gray-800">
                        <button mat-flat-button style="color: #0097b9; letter-spacing: 0.025em; padding-right: 0.3rem" class="rounded-full" aria-label="Example icon button with a heart icon">EXPAND
                            <mat-icon style="font-size: 15px !important; vertical-align: bottom">build</mat-icon>
                        </button>
                    </span>
                </td>
            </ng-container>


            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail relative" style="margin-left: -19px !important; padding-left: 0 !important" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="example-element-description w-full">
                            <h3 class="text-gray-900"> More details on transaction</h3>
                            <p>
                                <span class="example-element-description-attribution">Receiver:</span>
                                <span class="ml-2 text-gray-800">{{element["paymentTo"]}}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution">Transaction Id:</span>
                                <span class="ml-2 text-gray-800">{{formatId(element["t_id"])}}</span>
                            </p>


                            <p>
                                <span class="example-element-description-attribution">Reason:</span>
                                <span class="ml-2 text-gray-800">{{ element["reason"] }}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution">Transfer Fees:</span>
                                <span class="ml-2 text-gray-800">  <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{ element["currency"]}} </small></span>{{commaValues( element["transFees"])}} </span>
                            </p>


                            <p>
                                <span class="example-element-description-attribution">Balance After:</span>
                                <span class="ml-2 text-gray-800"> <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{ element["currency"]}} </small></span>{{commaValues( element["currentBalancePayer"])}} </span>

                            </p>

                            <p *ngIf="element['withLoan']['loanTaken']">
                                <span class="example-element-description-attribution">Loan:</span>
                                <span class="ml-2 text-gray-800"> <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{ element["currency"]}} </small></span>{{commaValues( element["withLoan"]["loanAmount"])}} </span>
                            </p>

                           <p *ngIf="element['withLoan']['loanTaken']">
                                <span class="example-element-description-attribution">Loan Fees:</span>
                               <span class="ml-2 text-gray-800">  <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{ element["currency"]}} </small></span>{{commaValues( element["withLoan"]["loanFees"])}}</span>
                            </p>

                            <p *ngIf="element['withLoan']['loanTaken']">
                                <span class="example-element-description-attribution">Loan Due Date:</span>
                                <span class="ml-2 text-gray-800">{{ element["withLoan"]["loanDueDate"] | date }}</span>
                            </p>

                        </div>

<!--                        <div class="flex flex-col absolute actions-postion">-->
<!--                            <button mat-stroked-button color="primary" (click)="resolveIssue(element)" class="rounded-full text-white">RESOLVE TICKET</button>-->
<!--                            <button mat-raised-button style="background:rgb(178, 60, 253) !important;color:white;margin-top:20px;" (click)="createTicket()" class="rounded-full text-white">CREATE TICKET</button>-->
<!--                        </div>-->

                    </div>
                </td>


            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [length]="resultsLength" [pageSize]="4"></mat-paginator>
    </div>

    <p class="text-center text-gray-800 py-8 text-base text-gray-800" *ngIf="!isLoadingResults && resultsLength<1">
        Enter customer telephone number to <br/>search customer's transaction*
    </p>


    <div class="flex" style="justify-content: center;" *ngIf="isLoadingResults || null">
        <div style="width: 15%;" class="py-8">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <small class="processing-text">Please wait</small>
        </div>

    </div>

</div>
