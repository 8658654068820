<ng-container *ngIf="!loading">

    <h3 mat-dialog-title style="margin-bottom: 1px !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity));font-weight: 400;">Create new team member</h3>
    <br/>
    <div mat-dialog-content>

        <!-- <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef
              style="text-align: left !important;
              padding-left: 4px !important;"> Name </th>
              <td mat-cell
              *matCellDef="let element"
              style="text-align: left !important;
              padding-left: 4px !important;"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="phoneNumber">
              <th mat-header-cell *matHeaderCellDef> Phone number </th>
              <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        <br/>
        -->

        <form class="flex flex-col" [formGroup]="teamsGroup">

            <div class="flex flex-row">
                <div class="w-49">
                    <mat-form-field appearance="fill">
                        <mat-label [floatLabel]="'always'">Agent phone number</mat-label>
                        <input matInput formControlName="customerNo"/>
                    </mat-form-field>
                </div>

                <div class="w-49 ml-15">
                    <mat-form-field appearance="fill">
                        <mat-label [floatLabel]="'always'">Username</mat-label>
                        <input matInput formControlName="staffId"/>
                    </mat-form-field>
                </div>
            </div>


            <div class="flex flex-row">
                <div class="w-49">
                    <mat-form-field appearance="fill">
                        <mat-label>Position</mat-label>
                        <mat-select formControlName="position">
                            <mat-option *ngFor="let position of positions" [value]="position">
                                {{position}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-15 w-49">
                    <mat-form-field appearance="fill">
                        <mat-label>Company</mat-label>
                        <mat-select formControlName="company">
                            <mat-option *ngFor="let company of companies" [value]="company">
                                {{company}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </form>


    </div>


    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="rounded-full">cancel</button>
        <button mat-raised-button
                (click)="createMember()"
                class="rounded-full font-700 tiny-letter-spacing"
                style="color:#fff !important;background: #0097b9 !important;font-weight: 600 !important;"
                cdkFocusInitial>CREATE AGENT
        </button>
    </mat-dialog-actions>
</ng-container>

<div class="py-8" *ngIf="loading">
    <mat-progress-bar></mat-progress-bar>
    <p class="text-gray-800 text-center text-base">Processing please wait</p>
</div>
