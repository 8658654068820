import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {PhoneNumber, WebService} from 'src/app/web-services/web.service';
import {MatSort, Sort} from '@angular/material/sort';

declare interface History {
    t_id: string;
    paymentTo: string;
    transType: string;
    amount: number;
    balance: number;
    status: string;
    timeStamp: string;
    currency: string;
}

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
    myControl = new FormControl('');
    options: any[] = [];
    notFound: boolean = false;
    loading: boolean = false;
    showDetails: boolean = false;
    noTransactionsYet = false;
    sortedData: any;
    ELEMENT_HISTORY: History[] = [];
    dataSource = new MatTableDataSource<History>([]);
    displayedColumns = [ 'status', 'amount', 'transType',  'balance', 'action'];
    headerRow = [ 'Status',  ' Amount', 'Type',  'New Balance', 'Action'];
    userProfile: { name: string; nin: string; _id: string };
    payload: any;
    payloadT: any;
    payloadP: any;
    payloadS: any;
    expandedElement: any;
    message: string = `Enter the customer's valid id to view the account profile*`;
    // displayedColumns: string[] = ['status', 'phone', 'loanBalance', 'loanLimit'];
    colors: any = ['text-info', 'text-warning', 'text-success', 'text-danger'];
    constructor(
        private svc: WebService, private router: Router) {

    }

    ngOnInit() {
        this.myControl.valueChanges.subscribe(event => {
            let size = `${event}`.length;
            if (size === 0) this.loading = false;
            if (size >= 3) this.loading = true;
            this.getUserProfile();
            const phone = new PhoneNumber();
            phone.line = event;
            const formatedPhoneNumber = /^([0-9]{6})$/.test(event) ? event : phone.e164;
            const formateAccIDNumber = this.userProfile._id;
            console.log("TEST", /^([0-9]{6})$/.test(event))

            if (/^0?(256|(5[0-4][0-9]))?((7([8|7|6]))|39)([0-9]{7})$|^([0-9]{6})$/.test(formatedPhoneNumber + "")) {
                console.log("TRUE", formatedPhoneNumber)
                this.svc.apiCall(
                    {
                        "task": "GET_ACCOUNT",
                        "customerNo": `FRI:${formatedPhoneNumber}@clinicpesa.public.user.sp1/SP`,
                        "accountId": `FRI:${formateAccIDNumber}@clinicpesa.public.user.sp1/SP`
                    }
                ).subscribe((data: any) => {
                    if (data['status']) {
                        this.payload = data['message'];
                        const simpleResponse = {
                            phone: this.payload['accDetails']['accountPhone'],
                            status: this.payload['accDetails']['accountStatus'],
                            fullName: this.payload['accDetails']['name'], loanLimit: `${this.payload['accDetails']['currency']}${this.payload['accDetails']['loanLimit']}`,
                            loanBalance: `${this.payload['currency']}${this.payload['loanBalance']}`
                        };
                        this.options = [simpleResponse];
                        console.log(this.options)
                        this.showDetails = true;
                        this.loading = false;
                        return;
                    }
                    this.loading = false;
                    this.notFound = true;
                    this.message = "Customer not found, check if correct number was entered";
                    this.options = [];
                }, err => {
                    this.loading = false;
                    this.message = "Session expired, please log out and try again";
                    this.svc.handleError(err);
                });
                this.svc.apiCall(
                    {
                        "task": "GET_TRANSACTIONS",
                        "customerName": "ALL",
                        "customerNo": `FRI:${formatedPhoneNumber}@clinicpesa.public.user.sp1/SP`,
                        "accountId": `FRI:${formateAccIDNumber}@clinicpesa.public.user.sp1/SP`,
                        "pagination": { "page": 0, "max": 3}
                    }
                ).subscribe((data: any) => {
                    if (data['status']) {
                        this.payloadT = data['message'].slice(0, 3);
                        this.noTransactionsYet = this.payloadT.length === 0;
                        this.dataSource = new MatTableDataSource<History>(this.payloadT);
                        console.log("TXT", this.payloadT);
                        return;
                    }
                }, err => {
                    this.svc.handleError(err);
                });
                this.svc.apiCall(
                    {
                        "task": "TEMP_SUSPENSION_STATUS",
                        "customerNo": `FRI:${formatedPhoneNumber}@clinicpesa.public.user.sp1/SP`,
                        "accountId": `FRI:${formateAccIDNumber}@clinicpesa.public.user.sp1/SP`
                    }
                ).subscribe((data: any) => {
                    console.log("SSS", data);
                    if (data['status']) {
                        this.payloadS = data['message'];
                        // console.log("SSS", this.payloadS);
                        // console.log("SSS", data);
                        return;
                    }
                }, err => {
                    this.svc.handleError(err);
                });

                this.svc.apiCall(
                    {
                        "task": "GET_COMPLAINT",
                        "queryBy":"CUSTOMER_NO",
                        "customerNo": `FRI:${formatedPhoneNumber}@clinicpesa.public.user.sp1/SP`,
                        "accountId": `FRI:${formateAccIDNumber}@clinicpesa.public.user.sp1/SP`
                    }
                ).subscribe((data: any) => {
                    console.log("TTT", data);
                        this.payloadP = data['status'];
                }, err => {
                    this.svc.handleError(err);
                });
            } else {

            }
        });
    }

    onSelectingCustomer(userId: string) {
        console.log("Customer number selected ", userId, this.payload)
        this.showDetails = true;
    }

    commaValues(value: any ) {
        return ((value ? value : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
    }


    sortData(sort: Sort) {
        const data = this.ELEMENT_HISTORY.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }

        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 't_id':
                    return this.compare(a.t_id, b.t_id, isAsc);
                case 'paymentTo':
                    return this.compare(+a.paymentTo, +b.paymentTo, isAsc);
                case 'transType':
                    return this.compare(+a.transType, +b.transType, isAsc);
                case 'amount':
                    return this.compare(+a.amount, +b.amount, isAsc);
                case 'balance':
                    return this.compare(+a.balance, +b.balance, isAsc);
                case 'status':
                    return this.compare(+a.status, +b.status, isAsc);
                case 'timeStamp':
                    return this.compare(+a.timeStamp, +b.timeStamp, isAsc);
                default:
                    return 0;
            }
        });

        this.dataSource = new MatTableDataSource<History>(this.sortedData);
    }

    compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }


    getColor(sel: String) {
        switch (sel) {
            case 'INITIATED':
                return this.colors[0];
            case 'PROCESSING':
                return this.colors[1];
            case 'CONFIRMED':
                return this.colors[2];
            case 'CANCELED':
                return this.colors[3];
            default:
                return this.colors[3];
        }

    }

    formatNumber(x: any) {
        if (x) {
            // return this.webService.process(parseFloat(x), 1);
        }
    }

    formatId(t_id: any) {
        if (t_id) {
            return t_id.substr(0, 7);
        }
    }


    getUserProfile(): void {
        try {
            const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
            if (!currentUser) {
                throw new Error('User does not exist');
            }

            this.userProfile = {
                _id: JSON.parse(localStorage.getItem('account_id')),
                name: currentUser['message']['firstName'] + ' ' + currentUser['message']['lastName'],
                nin: currentUser['message']['nin']
            };
            console.log('my hoe bitch', this.userProfile)
        } catch (e) {
            console.log(e);
        }

    }

    // _navigateToTransactions(userId: string): void{
    //   this.router.navigate(['/transactions', userId]);
    // }


}


