import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllTickets } from 'src/app/models/models';
import { WebService } from 'src/app/web-services/web.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  displayedColumns: string[] = ['complaintId', 'complaint'];
  dataSource = [this.data];
  commentGroup: FormGroup;
  loading: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: AllTickets, 
  private _fb: FormBuilder,
  private svc: WebService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.commentGroup = this._fb.group({
      comment: ['', [Validators.required]]
    });
  }

  submitComment(): void{
    this.loading = true;
    const commentGroup = this.commentGroup.getRawValue();
    const currentUser = localStorage.getItem('account_id');
    //  attachmentId: `CC${Math.ceil(Math.random() * 1000_000_000)}`,
    const payload = {
      task: "ADD_COMMENT",
      accountId: currentUser,
      complaintId: this.data.complaintId,
      comment: [
        {
          comments: commentGroup['comment'],
          commentBy: currentUser
        }
      ]
    };
    console.log(payload);
    this.svc.apiCall(payload).subscribe((data: any) => {
      if(data.status){
        this.loading = false;
        console.log('has passed', data);
        this.svc.notify('success', `Added comment succesfully `, 2);
        this.dialog.closeAll();
      }else{
        console.log('has errored',data);
        this.loading = false;
        this.svc.notify('error', data.message, 2);
      }
      }, error => {
        this.loading = false;
        this.svc.handleError(error);
      });
  }

}
