<h2 mat-dialog-title class="text-warning">Warning 
    <mat-icon class="text-warning inline-icon-17">warning</mat-icon></h2>
    
    <div mat-dialog-content>
       <p class="text-gray-800">
         {{data.operation}}
       </p>
     </div>


<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" 
    
    cdkFocusInitial>Ok</button>
  </div>