<ng-container *ngIf="!loading">
    <h2 mat-dialog-title style="margin-bottom: 1px !important;" class="text-gray-800">Resolve Complaint</h2>
    <p class="text-gray-800">Click on table content to show more details
        <mat-icon matTooltip="Click on the table to show more details" class="inline-icon text-gray-800">info</mat-icon>
    </p>
    <mat-dialog-content>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows
               class="relative"
        >

            <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important;
      padding-left: 4px !important;"> Customer name
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;
      padding-left: 4px !important;" class="text-gray-800"> {{element.customerName}} </td>
            </ng-container>

            <ng-container matColumnDef="complaint">
                <th mat-header-cell *matHeaderCellDef> Complaint Issued</th>
                <td mat-cell *matCellDef="let element" class="text-gray-800"> {{element.complaint}} </td>
            </ng-container>

            <ng-container matColumnDef="isClosed">
                <th mat-header-cell *matHeaderCellDef> Status</th>
                <td mat-cell *matCellDef="let element">
       <span
               [style.color]="element.isClosed['isClosedStatus'] ? 'rgb(16,185,129)': 'rgb(189, 69, 69)'">{{element.isClosed['isClosedStatus'] ? 'Closed' : 'Not Closed'}}</span>
                </td>
            </ng-container>

            <!-- <mat-icon class="text-gray-800"></mat-icon> -->

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" style="margin-left:-3% !important;padding-left: 0 !important;"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                        <div class="example-element-description w-full">
                            <h3 class="text-gray-800"><strong>More details on transaction</strong></h3>
                            <p>
                                <span class="example-element-description-attribution"> Category </span> <span
                                    class="ml-2 text-gray-800">{{element['category']}}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution"> Time issued </span> <span
                                    class="ml-2 text-gray-800">{{element['time'] | date}}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution"> Customer Number </span> <span
                                    class="ml-2 text-gray-800">{{element['customerNo']}}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution"> Transaction ID </span> <span
                                    class="ml-2 text-gray-800">{{element['transactionId']}}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution"> Category </span> <span
                                    class="ml-2 text-gray-800">{{element['category']}}</span>
                            </p>
                        </div>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        </table>


        <div style="padding-top:1.5rem;padding-bottom: 1.5rem;">

            <p style="font-size: 1rem;
    line-height: 1.5rem;" class="text-gray-800"><strong>Suggested actions</strong>
                <mat-icon
                        class="inline-icon">info
                </mat-icon>
            </p>
            <p>

                <mat-chip-list class="example-chip">
                    <mat-chip (click)="taskSelected = !taskSelected" [class]="taskSelected ? 'toggled-action cursor-pointer uppercase' : 'cursor-pointer uppercase'">
                        <!--data['resolveTask']-->
                        {{"START"}}
                    </mat-chip>
                    <mat-chip (click)="taskSelected = !taskSelected" [class]="taskSelected ? 'toggled-action cursor-pointer uppercase' : 'cursor-pointer uppercase'">
                        {{"RESOLVE"}}
                    </mat-chip>
                    <mat-chip color="accent" (click)="openDialog()" class="example-box cursor-pointer">
                        REFER TICKET
                    </mat-chip>
                </mat-chip-list>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="rounded-full">Cancel</button>
        <button mat-raised-button
                (click)="resolveIssue(data['resolveTask'])"
                class="rounded-full"
                color="primary" style="color:#fff !important;letter-spacing: 0.025em;"
                cdkFocusInitial>RESOLVE
        </button>
    </mat-dialog-actions>
</ng-container>


<!-- spinner  -->
<div class="py-8" *ngIf="loading">
    <mat-progress-bar></mat-progress-bar>
    <p class="text-gray-800 text-center text-base">Processing please wait</p>
</div>
  



