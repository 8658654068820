import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ThemePalette } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { FilteringPipe } from 'src/app/pipes/filtering.pipe';
import { PhoneNumber, WebService } from 'src/app/web-services/web.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AllTickets, TransactionFilterOptions } from 'src/app/models/models';
import { ErrorComponent } from '../error/error.component';
import { MatDialog } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { catchError, debounceTime, delay, filter, finalize, map, retry, startWith, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CreateTicketComponent } from '../create-ticket/create-ticket.component';
import { ResolvedialogComponent } from '../../sharables/resolvedialog/resolvedialog/resolvedialog.component';
import { delayedRetry } from 'src/app/sharables/retry-utility';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TransactionsComponent implements OnInit {
  displayedColumns: string[] = ["accountNo", "amount", "timeStamp", "status", "expand"];
  transactionOptions: string[] = [
    "ALL", "CASH_OUT", "CASH_IN", "WITHDRAW", "DEPOSIT", "PAYMENT",
   "TRANSFER", "TRANSFER_B", "REVERSAL", "LOAN", 
   "LOAN_REPAYMENT","LOAN_SAVINGS_PAYMENT", "REFUND", "BONUS", "PENALTY"
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  transactionFilter: any;
  account: any;
  ttype: any;
  tid: any;
  dateto: any;
  datefrom: any;
  userstransactions: any;
  filtered = false;
  transresults: any[] = [];
  currentViewedtransactions: any;
  transactionsGroup: FormGroup;
  resultsLength = 0;
  isLoadingResults = false;
  expandedElement: any;
  results: any[] = [];
  summary: {cashIn: number; cashOut: number};
  isRateLimitReached = false;
  autoSearch: boolean = false;
  searchCustomerContactCtrl = new FormControl();
  searchTransactionIdContactCtrl = new FormControl();

  filteredNumbers: any[];
  isLoadingNumbers = false;
  errorMsgNumbers: string;

  filteredTransactions: any;
  isLoadingTransaction = false;
  errorMsgTransaction: string;
  constructor(private transFilter: FilteringPipe, 
    private svc: WebService,
    private _fb: FormBuilder, 
    private dialog: MatDialog, private route: ActivatedRoute,
    ) {
    // Create 100 users
    // Assign the data to the data source for the table to render
    this.currentViewedtransactions = {
      accountNo: '',
      amount: 0,
      currentBalancePayee: 0,
      currentBalancePayer: 0,
      t_id: '',
      currency: 'UGX',
      reason: '',
      b2bPayment: '',
      transType: '',
      paymentTo: '',
      transFees: 0,
      status: '',
      withLoan: {
          loanTaken: false,
          loanAmount: 0,
          loanFees: 0,
          loanDueDate: 0,
      },
      timeStamp: 0,
      lastUpdated: 0,
  };

  this.transactionsGroup= this._fb.group({
    startDate: [''],
    endDate: [''],
    category: ['ALL'],
    transactionId: ['']
  });
  }

  ngOnInit(): void {// debounceTime(1000)
    this.searchCustomerContactCtrl.valueChanges
      .pipe(
        filter(text => /^0?(256|(5[0-4][0-9]))?((7([8|7|6]))|39)([0-9]{7})$/.test(text)),
        tap(() => {
          this.errorMsgNumbers = "";
          this.filteredNumbers = [];
          this.isLoadingNumbers = true;
        }),
        switchMap(value => {
          const accountId = localStorage.getItem('account_id').trim();
          let phone = new PhoneNumber();
          phone.line = value;
          return this.svc.apiCall({
            "task":"GET_ACCOUNT",
            "customerNo":`FRI:${phone.e164}@clinicpesa.public.user.sp1/SP`,
            "accountId": `FRI:${accountId}@clinicpesa.public.user.sp1/SP`
            })
            .pipe(
              catchError(err => this.svc.handleError(err)),
              finalize(() => {
                this.isLoadingNumbers = false
              }),
            )
        
        }
        )
      )
      .subscribe((data: any) => {
        if (data['message'] == undefined) {
          this.errorMsgNumbers = data['Error'];
          this.filteredNumbers = [];
        } else {
          this.errorMsgNumbers = "";
          this.filteredNumbers = (data.message) ?[data['message']] : null;
          // this.transactionsGroup.get('transactionId').setValue(this.filteredTransactions['phone']);
        }
      }, err => this.svc.handleError(err));


      this.searchTransactionIdContactCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsgTransaction = "";
          this.filteredTransactions = [];
          this.isLoadingTransaction = true;
        }),
        switchMap(value => {
          return this.svc.apiCall({
            transactionId: `${value}`,//bcad91f26c6d42199dc97dbbc563c4df
            task: "GET_TRANSACTION"
          })
            .pipe(
              catchError(err => this.svc.handleError(err)),
              finalize(() => {
                this.isLoadingTransaction = false
              }),
            )
        }
        )
      )
      .subscribe((data: any) => {
        if (data['message']['t_id'] === undefined) {
         
          this.errorMsgTransaction = data['message']['message'];
          this.filteredTransactions = [];
          this.svc.quickErrorResponse(this.errorMsgTransaction);
        } else {
          this.errorMsgTransaction = "";
          this.filteredTransactions = [data['message']];
          console.log(this.filteredTransactions);
          // this.transactionsGroup.get('transactionId').setValue(this.filteredTransactions['t_id']);
        }
      }, err => this.svc.handleError(err));
   
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.route.params.subscribe((param:any) => {
  //     if(param['userId'] !== '1'){
  //       this.transactionsGroup.get('customerNo').setValue(param['userId']);
  //       this.autoSearch = true;
  //       this.searchTransaction();
  //     }
  //   });
  // }



  filterTransactions() {
    const dataTobeFiltered: any = [];
    this.transactionFilter = {
        accountNo: this.account,
        transType: this.ttype,
        t_id: this.tid,
    };
    
    if (this.datefrom !== undefined) {
        this.userstransactions.map((data: any) => {
            const datePipe = new DatePipe('en-US');
            const myFormattedDate = datePipe.transform(
                data.timeStamp,
                'yyyy-MM-dd'
            );

            if (this.datefrom < myFormattedDate && this.dateto > myFormattedDate) {
                dataTobeFiltered.push(data);
            }
        });

        if (this.transactionFilter !== {}) {
            Object.keys(this.transactionFilter).forEach((key) =>
                this.transactionFilter[key] === undefined
                    ? delete this.transactionFilter[key]
                    : {}
            );
            console.log(this.transactionFilter);
            this.filtered = true;
            this.transresults = this.transFilter.transform(
                dataTobeFiltered,
                this.transactionFilter
            );
            this.currentViewedtransactions = this.transresults[0];
            console.log(this.currentViewedtransactions, this.transresults[0]);

        } else {
            this.transresults = dataTobeFiltered;
            this.currentViewedtransactions = this.transresults[0];

        }
    } else {
        Object.keys(this.transactionFilter).forEach((key) =>
            this.transactionFilter[key] === undefined || this.transactionFilter[key] === ''
                ? delete this.transactionFilter[key]
                : {}
        );
        console.log(this.transactionFilter);
        this.filtered = true;
        this.transresults = this.transFilter.transform(
            this.userstransactions,
            this.transactionFilter
        );
        this.currentViewedtransactions = this.transresults[0];

    }
    this.currentViewedtransactions = this.transresults[0];
}

viewTransaction(transaction: any) {
  console.log(transaction);
  this.currentViewedtransactions = transaction;
}

getHistory(page?: number) {
  this.transactionFilter = {
      category: 'history',
      phone: this.account,
      transactionType: this.ttype,
      data: '',
      page: page ? page : 0,
      order: 1,
      status: 'ALL'
  };
  console.log(this.transactionFilter);
  Object.keys(this.transactionFilter).forEach((key) =>
      this.transactionFilter[key] === undefined || this.transactionFilter[key] === ''
          ? this.transactionFilter[key] = 'ALL'
          : {}
  );
  console.log(this.transactionFilter);
  this.svc.apiCall(this.transactionFilter).subscribe((data: any) => {
      if (data.status) {
          this.transresults = data.message;
          this.currentViewedtransactions = this.transresults[0];
          console.log(this.currentViewedtransactions, this.transresults[0]);

          this.userstransactions = data.message;
          console.log(this.transresults);
      }
  });
  // this.selectClass = this.resetClass(this.getQuery());
}
    commaValues(value: any ) {
        return ((value ? value : 0).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
    }

    formatId(t_id: any) {
        if (t_id) {
            return t_id.substr(0, 7);
        }
    }

createTicket(): void {
  const dialogRef = this.dialog.open(CreateTicketComponent, {
    width: '66%',

  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}

resolveIssue(payload: any): void {
  const _phoneNumber = payload['accountNo'];
  
}


searchTransaction(): void{
  this.isLoadingResults = true;
  if( !(/^0?(256|(5[0-4][0-9]))?((7([8|7|6]))|39)([0-9]{7})$/.test(this.searchCustomerContactCtrl.value)) && !this.autoSearch){
    this.warnUser("Please check if customer number is correct or valid, & must not be left blank");
    this.isLoadingResults = false;
    return;
  }
 const transactionQuery = this.transactionsGroup.getRawValue();
 const phone = new PhoneNumber();
 phone.line = this.searchCustomerContactCtrl.value
 const adminNo = localStorage.getItem('account_id').trim();
 const payload: any = {};
 payload['task']= "GET_TRANSACTIONS";
 payload['accountId'] = `FRI:${adminNo}@clinicpesa.public.user.sp1/SP`;
 payload['customerNo'] = `FRI:${phone.e164}@clinicpesa.public.user.sp1/SP`;
 payload['customerName'] = transactionQuery['category'];
 payload['pagination'] = {"page":0, "max":100};//this.paginator.pageIndex
 this.paginator.page.pipe(
  startWith({}),
  switchMap(() => {
    this.isLoadingResults = true;
    return this.svc.apiCall(payload)
      .pipe(retry(5), catchError(() => observableOf(null))); //delayedRetry(1000,3), 
  }),
  map((data: any) => {
    this.isLoadingResults = false;
    this.isRateLimitReached = data === null;
    if (data === null) {
      return [];
    }
    this.resultsLength = data['message'].length;
    this.summary = {cashIn: data['summary']['cashIn'], cashOut: data['summary']['cashIn']};
    return data['message'];
  })
).subscribe((data: any) => {
  if (data.length > 0) {
      this.dataSource.data = data;
     
  } else {
    this.warnUser("Please check your network or log out and sign-in to try again");
  }
}, error => {
   this.svc.handleError(error);
});
}

resetTransaction(): void{
  this.transactionsGroup.reset();
  window.location.reload();
}

warnUser(message: string): void {
  const dialogRef = this.dialog.open(ErrorComponent, {
    data: {operation: message}
  });
}

}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}

