import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AllTickets } from 'src/app/models/models';
import { ReferdialogComponent } from '../../../pages/referdialog/referdialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { WebService } from 'src/app/web-services/web.service';
import { ErrorComponent } from '../../../pages/error/error.component';


@Component({
  selector: 'app-resolvedialog',
  templateUrl: './resolvedialog.component.html',
  styleUrls: ['./resolvedialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ResolvedialogComponent implements OnInit {
  taskSelected: boolean = false;
  expandedElement: AllTickets | null;
  panelOpenState = false;
  displayedColumns: string[] = ['customerName', 'complaint', 'isClosed'];
  loading: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<AllTickets>([this.data]);
  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: AllTickets, 
   private svc: WebService) {}

  ngOnInit(): void {
  }


  openDialog(): void {
     this.dialog.closeAll(); // close all opened dialogs
    const dialogRef = this.dialog.open(ReferdialogComponent, {
      width: '66%',
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  resolveIssue(task: any): void {
    if(!this.taskSelected){
      this.warnUser('Please select on of suggesed actions before resolving issue');
      return;
    }
    this.loading = true;
    //
    // let body = {
    //   "accountId": "ADMIN",
    //   "customerNo": "256773294150",
    //   "task":"CLOSE",
    //   "complaintId":"2c47f8549c8249839eba9255ced8c3ae",
    //   "isClosed":{
    //     "feedback":"This complaint has been closed sucessfully"
    //   }
    // }
    //
    // {
    //   "task":"UPDATE_ATTACHMENT",
    //     "accountId": "256773294150",
    //     "attachment":[{
    //   "attachId":"9ed3429436a340ae989ae0df800e3eff",
    //   "complaintStatus":"PROCESSING"
    // }]
    // }


    
    this.svc.apiCall({
      task: task,
      complaintId: this.data.complaintId,
      attachment: [{
        complaintStatus: "RESOLVED",
        attachId: this.data.accountId
      }]
    }).subscribe((data: any) => {
      if(!data.status){
        this.loading = false;
        this.svc.notify('error', data.message, 2);
        return;
      }

      this.loading = false;
      this.svc.notify('success', data.message, 2);
      this.dialog.closeAll();
      
    }, (err: any) => {
      this.loading = false;
      this.svc.handleError(err);
     
    });
  }

  warnUser(message: string): void {
    const dialogRef = this.dialog.open(ErrorComponent, {
      data: {message}
    });
  }

}
