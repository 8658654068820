<div class="mat-elevation-z8 m-t-10 bg-white" style="height: 100%">

    <br/>
    <div class="p-5">
        <form>
            <mat-form-field appearance="fill">
                <mat-label>Enter the customer's valid id:</mat-label>
                <input type="text"
                       placeholder="Pick one"
                       aria-label="Number"
                       required
                       maxlength="12"
                       matInput
                       [formControl]="myControl"
                       [matAutocomplete]="auto">
                <mat-icon matSuffix>search</mat-icon>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of options" [value]="option['phone']"
                                (click)="onSelectingCustomer(option['phone'])">
                        {{option['fullName']}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>
        </form>

        <!--        <div *ngIf="options.length >=1">-->
        <div *ngIf="showDetails">
            <div class="layout-row" style="margin-top: 30px; width: 47%; height: 100%; float:left">
                <div class="flex-100" >
                    <mat-card>
                        <md-card-content>
                            <p style="text-align: center; color: #00bcd4"><strong>PROFILE</strong></p>
                            <p *ngIf="payload.accDetails.name"><strong>Names:</strong> {{payload.accDetails.name }}</p>
                            <p *ngIf="payload.nin"><strong>Nin:</strong> {{payload.nin}}</p>
                            <p *ngIf="payload.phone"><strong>Phone:</strong> {{payload.phone}}</p>
                            <p *ngIf="payload.nextOfKeen"><strong>Next of keen:</strong> {{payload.nextOfKeen}}</p>
                            <p *ngIf="payload.location"><strong>Location:</strong> {{payload.location}}</p>
                            <p *ngIf="payload.email"><strong>Email:</strong> {{payload.email}}</p>
                            <p *ngIf="payload.gender"><strong>Gender:</strong> {{payload.gender}}</p>
                        </md-card-content>
                    </mat-card>
                    <mat-card>
                        <md-card-content>
                            <p style="text-align: center; color: #00bcd4"><strong>ACCOUNT</strong></p>
                            <p><strong>Saving:</strong> {{payload.accDetails.currency+" "+commaValues(payload.accDetails.savingsBalance)}}</p>
                            <p><strong>Loan:</strong> {{payload.accDetails.currency+" "+commaValues(payload.accDetails.loanBalance+payload.accDetails.loanFees)}}</p>
                            <p><strong>Loan Limit:</strong> {{payload.accDetails.currency+" "+commaValues(payload.accDetails.loanLimit)}}</p>
                            <p><strong>Loan Due Date:</strong> {{payload.accDetails.loanDue | date: "dd MMM yyyy"}}</p>
                            <p><strong>Subscriptions:</strong> {{payload.isMaMas && payload.accDetails.accountType? "clinicPesa MaMa's, Business Group" : payload.isMaMas ? "clinicPesa MaMa's" : payload.accDetails.accountType ? "Business Group" : "None" }}</p>
                        </md-card-content>
                    </mat-card>
                    <mat-card>
                        <md-card-content>
                            <p style="text-align: center; color: #00bcd4"><strong>ISSUES</strong></p>
                            <div class="table-responsive">

                                <mat-table
                                        class="table table-striped mdl-data-table  example-container mat-elevation-z8"
                                        #table [dataSource]="dataSource" matSort style="width: 100%;">


                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="status">
                                        <mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[0]}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let history"
                                                  class="text-left {{getColor(history.status)}}">
                                            {{history.status}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="amount">
                                        <mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[1]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-left" *matCellDef="let history">

                                            <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{history.currency}} </small></span>{{commaValues(history.amount)}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="transType">
                                        <mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[2]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-left" *matCellDef="let history">
                                            <span style="font-size: 12px; font-style: italic">  {{history.transType}}</span>
                                        </mat-cell>
                                    </ng-container>


                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="balance">
                                        <mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[3]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-center" *matCellDef="let history">
                                            <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{history.currency}} </small></span>
                                            {{commaValues(history.currentBalancePayer)}}
                                        </mat-cell>
                                    </ng-container>


                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="action">
                                        <mat-header-cell  *matHeaderCellDef mat-sort-header>
                                            {{headerRow[4]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-center" *matCellDef="let history">
                                            <button type="button" class="btn btn-indigo btn-sm m-0 btn-out">View</button>
                                        </mat-cell>
                                    </ng-container>


                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                                </mat-table>
                                <h3 class="text-center text-primary" *ngIf="noTransactionsYet">No transactions
                                    yet.</h3>
                                <div class="text-right more-history">
                                    <a routerLink="/transactions"><strong>More Transation Records ...</strong></a></div>
                                <!--main content && container fluid-->
                            </div>
                        </md-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="layout-row" style="margin-top: 30px; width: 47%; height: 100%; float:right">
                <div class="flex-100">
                    <mat-card>
                        <md-card-content>
                            <p style="text-align: center; color: #00bcd4"><strong>SECURITY</strong></p>
                            <p><strong>Account Status:</strong> {{payload.accDetails.accountStatus}}</p>
                            <p><strong>Pin
                                Status:</strong> {{ payloadS.instructionsPIN}}
                            </p>
                            <p><strong>Enable Pin Reset:</strong> {{payload.otp}}</p>
                            <p *ngIf="payloadS.isPinResetSuspension"><strong>Reset Attempts:</strong> {{payloadS.instructionsResetPIN}}</p>
                            <p><strong>Login Trace:</strong> {{"View"}}</p>
                        </md-card-content>
                    </mat-card>
                    <mat-card>
                        <md-card-content>
                            <p style="text-align: center; color: #00bcd4"><strong>LAST TRANSACTIONS</strong></p>
                            <div class="table-responsive">

                                <mat-table
                                        class="table table-striped mdl-data-table  example-container mat-elevation-z8"
                                        #table [dataSource]="dataSource" matSort style="width: 100%;">


                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="status">
                                        <mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[0]}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let history"
                                                  class="text-left {{getColor(history.status)}}">
                                            {{history.status}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="amount">
                                        <mat-header-cell class="text-right" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[1]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-left" *matCellDef="let history">

                                            <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{history.currency}} </small></span>{{commaValues(history.amount)}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="transType">
                                        <mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[2]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-left" *matCellDef="let history">
                                            <span style="font-size: 12px; font-style: italic">  {{history.transType}}</span>
                                        </mat-cell>
                                    </ng-container>


                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="balance">
                                        <mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header>
                                            {{headerRow[3]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-center" *matCellDef="let history">
                                            <span style="min-width: 25px;font-size: 7px;text-align: left;"><small>{{history.currency}} </small></span>
                                            {{commaValues(history.currentBalancePayer)}}
                                        </mat-cell>
                                    </ng-container>


                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="action">
                                        <mat-header-cell  *matHeaderCellDef mat-sort-header>
                                            {{headerRow[4]}}
                                        </mat-header-cell>
                                        <mat-cell class="text-center" *matCellDef="let history">
                                            <button type="button" class="btn btn-indigo btn-sm m-0 btn-out">View</button>
                                        </mat-cell>
                                    </ng-container>


                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                                </mat-table>
                                <h3 class="text-center text-primary" *ngIf="noTransactionsYet">No transactions
                                    yet.</h3>
                                <div class="text-right more-history">
                                    <a routerLink="/transactions"><strong>More Transation Records ...</strong></a></div>
                                <!--main content && container fluid-->
                            </div>
                        </md-card-content>
                    </mat-card>



                </div>
            </div>
        </div>


    </div>

    <div class="flex" style=" justify-content: center;" *ngIf="loading">
        <div style="width: 15%;" class="py-8">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <small class="processing-text"
            >Please wait</small>
        </div>

    </div>

            <p class="text-center text-gray-800 py-8 text-base" *ngIf="!showDetails">{{message}}</p>
</div>
