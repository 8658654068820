<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false">
        <!-- user info pannel -->
        <img src="/assets/logo.png" class="mx-auto-logo" height="69px" width="160px"/>
        <div class="w-50 h-auto mx-auto text-center">
            <div class="flex-center">
                <img [src]="'/assets/user.svg'" class="account-avatar cursor-pointer"/>
            </div>
                <h1 class="text-gray-800  tiny-letter-spacing" style="font-size: 16.5px;">{{userProfile.name}}</h1>
            <p class="font-400 text-gray-800">Role:  <strong><i>{{userProfile.position}}</i></strong></p>
            <p class="font-400 text-gray-800">Phone No:  <strong><i>{{userProfile._id}}</i></strong></p>
            <p class="font-400 text-gray-800">Username:  <strong style="color: #00bcd4">{{userProfile.uname ? userProfile.uname+"  &#10003;" : "..." }}</strong></p>
            <!--strong *ngIf="tObj.validMSG" style="color: #00bcd4"> ...
                                            &#10003; </strong>
                                        <strong *ngIf="!tObj.validMSG" style="color: #c0272d"> ...
                                            &#9998; </strong>-->
            <p class="font-400 text-gray-800" *ngIf="!userProfile.uname">
                <button mat-flat-button
                        (click)="setUserName()"
                        class="text-gray-600 btn-hover btn-out"> Set User Name... &#9998;
                </button>
            </p>

            <p class="pt-3">
                <button mat-flat-button
                        (click)="openDialog()"
                        class="ticket-cta uppercase  tiny-letter-spacing btn-wd">Create Ticket
                </button>
            </p>
            <p class="pt-3">
                <button mat-button class="text-gray-600 btn-hover btn-out" style="color: #00bcd4" (click)="lock()">
                    <mat-icon class="logout-btn text-gray-600" aria-hidden="false"
                              aria-label="Log out ">lock
                    </mat-icon>&nbsp;Lock
                </button>
            </p>
            <p class="pt-3">
                <button mat-button class="text-gray-600 btn-hover btn-out" style="color: #00bcd4" (click)="logout()">
                    <mat-icon class="logout-btn text-gray-600" aria-hidden="false"
                              aria-label="Log out ">logout
                    </mat-icon>&nbsp;Log out
                </button>

        </div>

    </mat-sidenav>
    <mat-sidenav-content>
        <!-- Add Content Here -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
