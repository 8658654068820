<ng-container *ngIf="!loading">
  <h3 mat-dialog-title 
  class="text-gray-800"
  style="margin-bottom: 1px !important;font-weight: 400;">Leave comment on ticket</h3>
  
  <div mat-dialog-content>
      <table mat-table [dataSource]="dataSource">
    
          <ng-container matColumnDef="complaintId">
            <th mat-header-cell *matHeaderCellDef 
            style="text-align: left !important;
            padding-left: 4px !important;"> Complaint ID </th>
            <td mat-cell 
            *matCellDef="let element" 
            style="text-align: left !important;
            padding-left: 4px !important;"> <span class="text-gray-800">{{element.complaintId}}</span> </td>
          </ng-container>
        
          <!-- <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef> Customer name </th>
            <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
          </ng-container> -->
      
        
          <ng-container matColumnDef="complaint">
            <th mat-header-cell *matHeaderCellDef> Complaint </th>
            <td mat-cell *matCellDef="let element"> <span class="text-gray-800">{{element.complaint}}</span> </td>
          </ng-container>
  
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    <br/>
  
    <p  style="font-size: 1rem;
      line-height: 1.5rem;" class="text-gray-800"><strong>Comment*</strong></p>
      <p>
        <form class="flex flex-col" [formGroup]="commentGroup">
          <mat-form-field appearance="fill" class="w-full">
            <textarea matInput placeholder="Leave comment on ticket" formControlName="comment"></textarea>
          </mat-form-field>
         </form>
  </div>
  
  
  <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="rounded-full">cancel</button>
      <button mat-raised-button (click)="submitComment()"
      class="rounded-full font-700"
      style="color:#fff !important;background: #0097b9 !important;letter-spacing: 0.025em;font-weight: 600 !important;"
      cdkFocusInitial >SAVE COMMENT</button>
  </div>
</ng-container>

<ng-container *ngIf="loading">
  <div class="py-8">
    <mat-progress-bar></mat-progress-bar>
    <p class="text-gray-800 text-center text-base">Processing please wait</p>
  </div>
</ng-container>