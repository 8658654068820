<h2 mat-dialog-title style="margin-bottom: 1px !important;font-weight: 400;" class="text-gray-800">Re-Assign Complaint</h2>
<br/>

<h3 class="text-gray-800"><strong>Complaint Id:&nbsp;</strong> {{data.complaintId}}</h3>

<br/>
<p  style="font-size: 1rem;
line-height: 1.5rem;" class="text-gray-800"><strong>Re-Assign Complaint to* *</strong></p>

<mat-form-field appearance="fill">
  <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchTeamContactCtrl">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let user_account of filteredNumbers" [value]="user_account.phone"  (click)="onSelectingCare(user_account)">
        <span><b>{{user_account['accountDetails']['firstName'] + " " + user_account['accountDetails']['lastName']}}</b> ({{user_account.phone}})</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <ng-container *ngIf="errorMsg;">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option>Whoops* {{errorMsg}}</mat-option>
    </mat-autocomplete>
  </ng-container>
<mat-icon matSuffix>search</mat-icon>
</mat-form-field>


<form [formGroup]="referGroup" class="flex flex-col">
<div class="flex flex-row">
  <div class="w-49">
    <mat-form-field appearance="fill">
      <mat-label [floatLabel]="'always'">To whom / role</mat-label>
      <input matInput  formControlName="who" type="text"/>
    </mat-form-field>
  </div>
  
  <div class="ml-15 w-49">
  
    <mat-form-field appearance="fill">
      <mat-label [floatLabel]="'always'">Attach time</mat-label>
      <input matInput formControlName="attachTime"  min="09:00" max="18:00" type="time"/>
    </mat-form-field>
  </div>
</div>


    <p  style="font-size: 1rem;
    line-height: 1.5rem;" class="text-gray-800"><strong>Reason*</strong></p>
    <p>
        
    <p>
      <mat-form-field appearance="fill">
        <textarea matInput 
        placeholder="Ex Help this customer out on ex..." 
        formControlName="reason"></textarea>
      </mat-form-field>
    </p>
</form>

  

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close  class="rounded-full">Cancel</button>
    <button mat-raised-button 
    (click)="referTicket(data.attachment[0].to)"
    class="rounded-full"
    color="primary" style="color:#fff !important;letter-spacing: 0.025em;"
    cdkFocusInitial>Confirm Referral</button>
  </mat-dialog-actions>
