import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { HomeComponent } from './pages/home/home.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AuthguardGuard } from './guards/authguard.guard';
import {AuthLayoutComponent} from "./layout/auth/auth-layout.component";

const routes: Routes = [
  {
    path: '',
    component: SidebarComponent,
    canActivate:[AuthguardGuard], // refactor to can activate children for all child components
    children: [
      {
        path: '',
        component: HomeComponent,
        children: [
          {
            path: '',
            component: TicketsComponent,
            canActivate: [AuthguardGuard]
          },
          {
            path: 'transactions',
            component: TransactionsComponent,
            canActivate: [AuthguardGuard]
          },
          {
            path: 'accounts',
            component: AccountsComponent,
            canActivate: [AuthguardGuard]
          },
          {
            path: 'teams',
            component: TeamsComponent,
            canActivate: [AuthguardGuard]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./auth/auth.module').then((mod) => mod.AuthModule),
    },
    ]
  },
  // { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  // { path: 'lock', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {path: "**", redirectTo: "/", pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    paramsInheritanceStrategy: 'always',
    initialNavigation: 'enabled',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
