import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders,} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {throwError, timer} from "rxjs";
import {Observable, pipe} from 'rxjs/Rx';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import {ConnectionService} from "ng-connection-service";
declare const $: any;

export class PhoneNumber {
    country = '256';
    line: string;
    code: string;
    login_id: string;

    get e164(): string | void{
        console.log(this.line)
        if (this.line.length === 12) {
            const num = this.line;
            return `${num}`;
        } else  if (this.line.length === 13) {
            const num = this.line.slice(1);
            return `${num}`;
        } else if (this.line.length === 10) {
            const num = this.country + (this.line.substr(1, 10));
            return `${num}`;
        } else if (this.line.length === 6) {
            return this.line;
        } else if (this.line.length === 9) {
            const num = this.country + this.line;
            return `${num}`;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class WebService {
    // headers
    private _refreshNeeded$ = new Subject<void>();
    reloadMember: any = new Subject();
    notificationsSubject: any = new Subject();
    notifications: any = new Subject();

    constructor(private http: HttpClient, private router: Router, private tS: MatSnackBar) {
        const x = this.notificationsSubject.map((user: any) => {
            console.log("LOG", user);
            return Observable.timer(parseInt(user.message, 10) * 1000);
        });
        x.switch().subscribe((e: any) => {
            console.log("FIRE", e);
            this.lockscreen()
        });
    }

    lockscreen() {
        const current = localStorage.getItem('currentUser');
        if (current) {
            const currentUser = JSON.parse(current);
            currentUser.access_token = 'new token';
            currentUser.lock = true;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.router.navigate(['/lock']);
        }
    }

    logout() {
        return this.http.delete<any>(environment.prodURL + '/auth')
            .pipe(
                tap(data => JSON.stringify(data))
            );
    }


    logout_data() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('account_type');
        localStorage.removeItem('account_id');
        this.router.navigate(['/login']);
    }

    get refreshNeeded$() {
        return this._refreshNeeded$;
    }

    register(facility: any) {
        return this.http
            .post(environment.prodURL + 'v1/account/pre_registration', facility,);
    }


    grtUser(phonenumber: any) {
        return this.http
            .get(environment.prodURL + 'v1/account/user/' + phonenumber,
                {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/xml')
                        .append('Access-Control-Allow-Methods', 'GET')
                        .append('Access-Control-Allow-Origin', '*')
                        .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
                    responseType: 'text'
                }
            )
    }

    getTeamMembers() {
        return this.http
            .get(environment.prodURL + 'v1/team/getmyTeam')
    }

    startLoader(name: string, status: boolean = false, spinner: NgxSpinnerService) {
            if(status) {
                spinner.show(name);

                setTimeout(() => {
                    /** spinner ends after 5 seconds */
                    spinner.hide(name);
                }, 50000);
            } else  spinner.hide(name);
    }

    login(obj: any) {
        const id = obj.accountId;
        const token = 'Basic ' + btoa(`${obj.accountId}:${obj.password}`);
        return this.http.post(environment.prodURL + '/auth',
            {observe: 'response'}, {headers: new HttpHeaders({'Authorization': token, 'MSISDN': id})}).pipe(tap((user: any) => {
            return user;
        }));

    }

    userManagement(acc: string, action: string, transactionId: string, pin: string): Observable<any> {
        console.log('Here', acc, action, transactionId, pin)
        let hold = this.http.put<any>(environment.prodURL + '/auth', {
            recipient: 'FRI:' + acc + '@clinicpesa.public.user.sp1/SP',
            requestType: action,
            transactionId: transactionId,
            pin: pin
        }, {headers: new HttpHeaders({'MSISDN': acc})});

        hold.pipe(tap(e => console.log("LOG ", e)));
        return hold;
    }

    apiCall(body:any) {
        console.log("LOGGGG.....", environment.prodURL + '/api/care', body)
        return this.http.post(environment.prodURL + '/api/care', body).map(e => { console.log("DD.....", e); return e; });
            //.pipe(tap(e => console.log("YES ", e)));
    }


    getInt() {
        return this.http.get(environment.prodURL + '/auth');
    }

    notify(type: string, message: string, duration: number = 5000) {
        const gratification = ', thank you!';
        const action = 'OK';
        switch (type) {
            case 'success' :
                this.tS.open(message + gratification, action, {panelClass: 'success', horizontalPosition:"right",verticalPosition:"top", duration:duration});
                break;
            case 'error' :
                this.tS.open(message + gratification ,action, {panelClass: 'danger', horizontalPosition:"right",verticalPosition:"top", duration:duration});
                break;
            case 'info' :
                this.tS.open(message + gratification, action, {panelClass: 'info', horizontalPosition:"right",verticalPosition:"top", duration:duration});
                break;
            case 'warning' :
                this.tS.open(message + gratification, action, {panelClass: 'warning', horizontalPosition:"right",verticalPosition:"top", duration:duration});
                break;
        }

    }

     handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
            if(error.status === 400){
                this.notify('error', "Session expired while submitting, log in again to submit", 1);
                return null;
            }
            this.notify('error', error.error.message, 1);
        }
        // Return an observable with a user-facing error message.
        return throwError(
          'Something bad happened; please try again later.');
      }

      quickErrorResponse(message: string): void{
          this.notify('error', message, 2);
      }

    pinIndicate(data: string) {
        switch (data.length) {
            case 1:
                return ' x.__.__.__ ';
            case 2:
                return ' x.x.__.__ ';
            case 3:
                return ' x.x.x.__ ';
            case 4:
                return ' x.x.x.x ';
            case 5:
                return ' x.x.x.x.x';
            default:
                return '';
        }
    }


    getNames() {
        const acc = localStorage.getItem('account_type');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return (acc === 'USER' ? `${currentUser.message.lastName}    ${currentUser.message.firstName}` :
            acc === 'FACILITY' ? currentUser.message.facilityName :
                acc === 'BUSINESS' ? currentUser.message.companyName : 'UNKNOWN');
    }

    openRightAccount() {
        const b4user = localStorage.getItem('currentUser');
        if (b4user) {
            const user = JSON.parse(b4user);
            if (user.lock) {
                this.lockscreen();
            } else {
                this.setRoutes(localStorage.getItem('account_type'))
            }

        }
    }


    setRoutes(acc_type: string) {
        // get return url from route parameters or default to '/'
        // let path = this.route.snapshot.queryParams['returnUrl'];
        const user = '';
        const business = '/business';
        const facility = '/facility';
        const returnUrl = (acc_type === 'USER') ? (user) : (acc_type === 'FACILITY') ? (facility) : (acc_type === 'BUSINESS') ? (business) : '';
        this.router.navigate([returnUrl]);

    }





}
