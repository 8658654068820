import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PhoneNumber, WebService } from 'src/app/web-services/web.service';

@Component({
  selector: 'app-teamsdialog',
  templateUrl: './teamsdialog.component.html',
  styleUrls: ['./teamsdialog.component.scss']
})
export class TeamsdialogComponent implements OnInit {
  teamsGroup: FormGroup;
  loading: boolean = false;
  companies: string[] = ['MTN', 'CLINICPESA'];
  positions: string[] = ['ADMIN', 'MANAGER', 'TECH_SUPPORT', 'AGENT'];
  staffID: string =  '';//`CP${Math.ceil(Math.random() * 1000_000_000)}`; // auto generates stuffID automatically
  constructor(private _fb: FormBuilder,
    private svc: WebService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.teamsGroup =  this._fb.group({
      staffId: [this.staffID],
      position: ['', [Validators.required]],
      company: ['', [Validators.required]],
      who: [''],
      customerNo: ['', [Validators.required, Validators.maxLength(12), Validators.pattern(/^0?(256)?((7([8|7]))|39)([0-9]{7})$/)]]
    });
  }

  createMember(): void{
    this.loading = true;
    const teams = this.teamsGroup.getRawValue();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const accountId = JSON.parse(localStorage.getItem('account_id'));
    const phone = new PhoneNumber();
    phone.line = teams['customerNo'];
    const customerNo = phone.e164;
    teams['who']=currentUser['message']['account'];
    const adminPhone = new PhoneNumber();
    adminPhone.line = teams['customerNo'];
    const payload = {
      "task":"CREATE_CUSTOMER_CARE",
      "customerNo": `FRI:${customerNo}@clinicpesa.public.user.sp1/SP`,
      "accountId": `FRI:${adminPhone.e164}@clinicpesa.public.user.sp1/SP`,
      "customerCare" : 
      { 
          "staffId" : teams['staffId'], 
          "position" : teams['position'], 
          "company": teams['company'], 
          "who": adminPhone.e164
      }
    };
    
    this.svc.apiCall(payload).subscribe((data: any) => {
      if(data.status){
        this.loading = false;
        this.svc.notify('success', data.message, 2);
        this.dialog.closeAll();
      }else{
        this.loading = false;
        this.svc.notify('error', data.message, 2);
      }
      }, error => {
        this.loading = false;
        this.svc.handleError(error);
      });
  }

}
