import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {Transaction} from 'src/app/models/models';
import {PhoneNumber, WebService} from 'src/app/web-services/web.service';

@Component({
    selector: 'app-create-ticket',
    templateUrl: './create-ticket.component.html',
    styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {
    myControl = new FormControl('');
    transactionControl = new FormControl('');
    options: any[] = [];
    transactionOptions: string;//Transaction
    displayedColumns: string[] = ['name', 'phoneNumber'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([{
        name: "",
        phoneNumber: ""
    }]);
    userProfile: { name: string; nin: string; _id: string, uname: string, position: string, co: string };
    categories: string[] = ['ACCOUNT_ISSUES', 'TRANSACTION_ISSUES', 'OTHER_ISSUES'];
    tasks: string[] = ['pinReset'];
    createTicketGroup: FormGroup;
    loading: boolean = false;
    transactionIssues: string[] = ["Reverse", "Cancel", "Complete"];
    accountIssues: string[] = ["pinReset", "checkStatus", "block", "unblock"];
    isSpinning: boolean = false;
    notFound: boolean = false;
    message: string = "";
    formSpinner: boolean = false;
    fullName: string = "";
    transIDNotFound: boolean = false;
    transFormSpinner: boolean = false;

    constructor(private _fb: FormBuilder,
                private svc: WebService, private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.getUserProfile();
        this.createTicketGroup = this._fb.group({
            customerNo: ['', [Validators.required, Validators.maxLength(12), Validators.pattern(/^0?(256)?((7([8|7]))|39)([0-9]{7})$/)]],
            customerName: ['', [Validators.required]],
            complaint: ['', [Validators.required]],
            resolveTask: ['', [Validators.required]],
            category: ['', [Validators.required]],
            transactionId: ['']
        });
        // alert user if entered transaction isses
        this.createTicketGroup.get('category').valueChanges.subscribe(val => {
            this.checkCategories(val);
        });

        // auto-complete for searching customer number
        this.myControl.valueChanges.subscribe(event => {
            let size = `${event}`.length;
            if (size === 0) {
                this.formSpinner = false;
                this.notFound = false;
                this._resetForm();
            }
            if (size >= 3) this.formSpinner = true;
            const phone = new PhoneNumber();
            phone.line = event;
            const formatedPhoneNumber = phone.e164;
            this.options = [];
            if (/^0?(256|(5[0-4][0-9]))?((7([8|7|6]))|39)([0-9]{7})$/.test(formatedPhoneNumber + "")) {
                this.svc.apiCall(
                    {
                        "task": "GET_ACCOUNT",
                        "customerNo": `FRI:${formatedPhoneNumber}@clinicpesa.public.user.sp1/SP`,
                        "accountId": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`
                    }).subscribe((data: any) => {
                    console.log("DATA", formatedPhoneNumber, data)
                    if (data['status']) {
                        const payload = data['message']['accDetails'];
                        const simpleResponse = {
                            phone: payload['accountPhone'],
                            status: payload['accountStatus'],
                            fullName: payload['name'], loanLimit: `${payload['currency']}${payload['loanLimit']}`,
                            loanBalance: `${payload['currency']}${payload['loanBalance']}`
                        };
                        this.setCustomerAccount(simpleResponse);
                        this.formSpinner = false;
                        this.notFound = false;
                        this.options = [simpleResponse];
                        return;
                    }
                    this.createTicketGroup.get('customerName').setValue(""); //reset name
                    this.createTicketGroup.get('customerNo').setValue(formatedPhoneNumber);
                    this.options = [];
                    this.formSpinner = false;
                    this.notFound = true;
                    this.message = "Customer not found, check if correct number was entered";
                });
            }
        });
        // automatically query
        // transactionControl
        this.transactionControl.valueChanges.pipe(
            debounceTime(500),
            tap(() => {
                this.transactionOptions = "";
                this.message = "Customer not found, check if correct number was entered";
                this.transFormSpinner = false;
                this.transIDNotFound = false;
            }),
            switchMap(event =>
                this.svc.apiCall(
                    {
                        customerNo: `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
                        accountId: `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
                        transactionId: `${event}`,//bcad91f26c6d42199dc97dbbc563c4df
                        task: "GET_TRANSACTION"
                    }
                ).pipe(
                    finalize(() => {

                        this.transFormSpinner = true;
                    })
                ))
        ).subscribe((data: any) => {
            console.log("RE:::", data)
            if (data['status'] === true) {
                this.transactionOptions = data['message']['t_id'];
                this.createTicketGroup.get('transactionId').setValue(this.transactionOptions);
                this.transFormSpinner = false;
                this.transIDNotFound = false;
                return;
            } else if (data['status'] === false) {
                this.transFormSpinner = false;
                this.transIDNotFound = false;
                this.message = data['message'];
            } else {
                this.transFormSpinner = false;
                this.transIDNotFound = false;
                this.message = "Transaction ID not found, check if transaction ID";
            }

        }, err => {
            this.transFormSpinner = false;
            this.message = "Session expired, please log out and try again";
            this.svc.handleError(err);
        });


    }


    createTicket(): void {
        const currentUser = JSON.parse(localStorage.getItem('account_id'));
        const ticketGroup = this.createTicketGroup.getRawValue();
        ticketGroup['task'] = 'RECEIVE';
        const _phone = new PhoneNumber();
        console.log("Resolve task", ticketGroup['resolveTask']);
        ticketGroup['resolveTask'] = ticketGroup['resolveTask'] == '' ? 'undefined' : ticketGroup['resolveTask'];
        ticketGroup['resolveTask'] = ticketGroup['resolveTask'].length < 3 ? 'undefined' : ticketGroup['resolveTask'];
        _phone.line = ticketGroup['customerNo'];
        console.log(_phone.line, ticketGroup['customerNo'])
        ticketGroup['customerNo'] = `FRI:${_phone.e164}@clinicpesa.public.user.sp1/SP`;
        ticketGroup['accountId'] = `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`;
        console.log("LOG....?", ticketGroup['accountId'], ticketGroup['customerNo'])
        this.loading = true;
        this.svc.apiCall(ticketGroup).subscribe((data: any) => {
            console.log("DDF", data)
            if (data.status) {
                this.loading = false;
                console.log(data);
                this.svc.notify('success', 'Successfully created ticket', 2);
                this.dialog.closeAll();
            } else {
                this.loading = false;
                this.svc.notify('error', data.message, 2);
            }
        }, error => {
            this.loading = false;
            this.svc.handleError(error);
        });
    }


    setCustomerAccount(userInfo: any): void {
        this.fullName = userInfo['fullName'];
        this.createTicketGroup.get('customerNo').setValue(userInfo['phone']);
        this.createTicketGroup.get('customerName').setValue(this.fullName);
    }

    _resetForm() {
        this.fullName = "";
        this.createTicketGroup.reset();
    }

    checkCategories(val: any): void {
        try {
            if (val === 'TRANSACTION_ISSUES') {
                this.tasks = this.transactionIssues;
            } else if (val === 'ACCOUNT_ISSUES') {
                console.log('i am cakkedm acciunt issues');
                this.tasks = this.accountIssues;
            } else {
                this.tasks = ['undefined'];
            }
        } catch (err) {
            return null;
        }
    }

    trimTransactionID(id: string): string {
        return id ? id.substring(0, 8) : undefined;
    }

    checkCategoryType(category: string): boolean {
        return this.createTicketGroup.get('category').value === category;
    }

    getUserProfile(): void {
        try {
            const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
            if (!currentUser) {
                throw new Error('User does not exist');
            }

            this.userProfile = {
                _id: JSON.parse(localStorage.getItem('account_id')),
                name: currentUser['message']['firstName'] + ' ' + currentUser['message']['lastName'],
                nin: currentUser['message']['nin'],
                uname: currentUser['message']['customerCare']['staffId'],
                position: currentUser['message']['customerCare']['position'],
                co: currentUser['message']['customerCare']['company']
            };
            console.log('my hoe bitch', this.userProfile)
        } catch (e) {
            console.log(e);
        }
    }


}

