import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {CreateTicketComponent} from 'src/app/pages/create-ticket/create-ticket.component';
import {Router} from '@angular/router';
import {Ticket} from 'src/app/models/models';
import {WebService} from 'src/app/web-services/web.service';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {SetUsernameComponent} from "../../pages/set-username/set-username.component";

@Component({
    selector: 'app-home',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    title = 'clinicPesa Customer Care';
    networkMode: string = 'online';
    ticketName: string;
    ticketStatus: string = '';
    ticketCategory: any;
    createdDate: any;
    refer: string = '';
    accountId: any;
    phone: string;
    message: string;
    currentUser: any;
    userRole: any;
    accountType: any;
    fetchedTickets: any[] = [];
    transactionTicket: any[] = [];
    referredTickets: any[] = [];
    closedTickets: any[] = [];
    myTickets: any[] = [];
    clinicPesaTickets: any[] = [];
    MTNTickets: any[] = [];
    userProfile: { name: string; nin: string; _id: string, uname: string, position: string };
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(private breakpointObserver: BreakpointObserver,
                public dialog: MatDialog, private route: Router,
                private svc: WebService, private dbService: NgxIndexedDBService) {

    }

    ngOnInit(): void {
        this.getUserProfile();
        this.svc.notifications.subscribe((e: any) =>  {
            console.log("UN FIRE", e);
            if(e.action === 'UNUPDATE') {this.updateUN(e.un)}});
    }

    updateUN(un: string):void{
        console.log("CALL UN", un);
        this.svc.apiCall({
            "task":"CHECK_UN",
            "customerNo": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
            "accountId": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
            "customerCare": {
                "staffId": un
            }
        }).subscribe((data: any) => {
            console.log("UN", data)
            if (data.status) {
                this.userProfile.uname = un;
            } else {
                this.userProfile.uname = '...';
            }
        }, error => {
            this.userProfile.uname = '...';
            this.svc.handleError(error);
        });


    }

    setUserName(): void {
        const dialogRef = this.dialog.open(SetUsernameComponent, {
            width: '33%',

        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(CreateTicketComponent, {
            width: '66%',

        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }


    logout() {
        this.svc.logout().subscribe((data: any) => {
                if (data.status) {
                    this.svc.logout_data();
                } else {
                    this.svc.logout_data();
                }
            }, () => {
                this.svc.logout_data();
            });
    }

    lock() {
        this.svc.logout().subscribe((data: any) => {
            if (data.status) {
                this.svc.lockscreen();
            } else {
                this.svc.lockscreen();
            }
        }, () => {
            this.svc.lockscreen();
        });
        this.svc.lockscreen();
    }

    createTicket() {
        // call material-dialog to close();
        const date = new Date();
        const timestamp = date.getTime();
        const ticket: Ticket = {
            ticketName: this.ticketName,
            phone: this.phone,
            ticketStatus: this.ticketStatus,
            category: this.ticketCategory,
            date: this.createdDate,
            referTo: {recipient: this.refer, senderId: this.accountId},
            ticketId: this.accountId + timestamp,
            accountId: this.accountId,
            message: this.message,

        };
        console.log(ticket);
        if (this.networkMode === 'offline') {
            // this.idbService.addItems('Items', ticket);
        } else {
            this.svc.apiCall(ticket).subscribe((data: any) => {
                if (data.status) {
                    //  alert reason and refresh the ticket;
                    console.log(data.message, 'here i am');
                    this.svc.refreshNeeded$.subscribe(() => {
                        // this.fetchTickets();

                    });
                } else {
                    ticket.synced = false;
                    console.log(ticket.synced, 'synced');
                    this.dbService
                        .add('Tickets', ticket
                        )
                        .subscribe((key) => {
                            console.log('key: ', key);
                        });
                }
            }, error => {
                ticket.synced = false;
                console.log(ticket.synced, 'synced');

                this.dbService
                    .add('Tickets', ticket)
                    .subscribe((key) => {
                        console.log('key: ', key);
                    });


            });
        }

    }


    // fetchTickets(): void {
    //     this.currentUser = JSON.parse(localStorage.getItem('account_id'));
    //     this.accountId = this.userRole === 'ADMIN' ? 'ALL' : this.currentUser;
    //     if (this.networkMode === 'online') {
    //         this.apiCall.getTickets(this.accountId, this.accountType).subscribe((data: any) => {
    //             if (data.status) {
    //                 // this.fetchedTickets = data.tickets.reverse();
    //                 this.fetchedTickets = [{
    //                     ticketName: " Chris's Ticket",
    //                     phone: "256773294150",
    //                     message: "Chris",
    //                     category: "transaction",
    //                     ticketStatus: 'open',
    //                     referTo: {recipient: 'Chris'},
    //                     date: 1620457321
    //                 }];
    //                 // console.log(this.fetchedTickets);
    //                 this.filterTickets();
    //             } else {
    //                 this.dbService.getAll('Tickets').subscribe((dat: any[]) => {
    //                     // this.fetchedTickets = dat.reverse();
    //                     this.fetchedTickets = [{
    //                         ticketName: " Chris's Ticket",
    //                         phone: "256773294150",
    //                         message: "Chris",
    //                         category: "transaction",
    //                         referTo: {recipient: 'Chris'},
    //                         ticketStatus: 'open',
    //                         date: 1620457321
    //                     }];
    //                     console.log(this.fetchedTickets);
    //                     this.filterTickets();
    //                 });
    //             }
    //         }, error => {
    //             console.log('am error now', error);
    //
    //             this.dbService.getAll('Tickets').subscribe((data) => {
    //                 // this.fetchedTickets = data.reverse();
    //                 console.log('data bitch', data)
    //                 this.fetchedTickets = [{
    //                     ticketName: " Chris's Ticket",
    //                     phone: "256773294150",
    //                     message: "Chris",
    //                     category: "transaction",
    //                     ticketStatus: 'open',
    //                     referTo: {recipient: 'Chris'},
    //                     date: 1620457321
    //                 }];
    //                 console.log(this.fetchedTickets);
    //                 this.filterTickets();
    //             });
    //         });
    //     } else {
    //
    //     }
    // }


    filterTickets(): void {
        this.fetchedTickets.map((ticket) => {
            if (ticket.category === 'transactions') {
                this.transactionTicket.push(ticket);
                console.log(this.transactionTicket);
            }
            if (ticket.referTo.recipient !== '') {
                this.referredTickets.push(ticket);
                if (this.accountType === 'admin') {
                    this.referredTickets.map((data: any) => {
                        if (data.referTo.recipient === 'Admin') {
                            this.myTickets.push(data);
                            console.log(this.myTickets);
                        }
                    });
                }
            }
            if (ticket.ticketStatus.toLowerCase() === 'closed') {
                this.closedTickets.push(ticket);
                console.log(this.closedTickets);
            } else if (ticket.ticketStatus === 'pending') {
                this.clinicPesaTickets.push(ticket);
            } else if (ticket.ticketStatus === 'MTN') {
                this.MTNTickets.push(ticket);
            }

        });

    }


    getUserProfile(): void {
        try {
            const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
            if (!currentUser) {
                throw new Error('User does not exist');
            }

            this.userProfile = {
                _id: JSON.parse(localStorage.getItem('account_id')),
                name: currentUser['message']['firstName'] + ' ' + currentUser['message']['lastName'],
                nin: currentUser['message']['nin'],
                uname: currentUser['message']['customerCare']['staffId'],
                position: currentUser['message']['customerCare']['position']
            };
            console.log('my hoe bitch', this.userProfile)
        } catch (e) {
            console.log(e);
        }

    }


}
