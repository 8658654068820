<div class="mat-elevation-z8 m-t-10 bg-white">


    <div class="relative">

        <div class="btn-section" style="padding-top:1em;padding-left:5px;">
            <button mat-button color="primary" (click)="openDialog()" class="rounded-full tiny-letter-spacing relative">
                <span><mat-icon class="inline-icon-16">add_circle</mat-icon> &nbsp;&nbsp;&nbsp; TAP TO ADD NEW MEMBER</span></button>
        </div>

        <br/>
        <form class="align-menu-right" [formGroup]="categoryMenuGroup">
            <mat-form-field class="category-form" [floatLabel]="'never'">
                <mat-label>Select category</mat-label>
                <mat-select formControlName="category">
                    <mat-option class="tiny-letter-spacing" *ngFor="let option of filterOptions" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <!-- <br/>   -->
        <div class="p-5">
            <mat-form-field appearance="fill">
                <mat-label>Search team member</mat-label>
                <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchTeamContactCtrl">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let user_account of filteredNumbers" [value]="user_account.phone">
                            <span><b>{{user_account['accountDetails']['firstName'] + " " + user_account['accountDetails']['lastName']}}</b> ({{user_account.phone}})</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>

                <ng-container *ngIf="errorMsg;">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option>Whoops* {{errorMsg}}</mat-option>
                    </mat-autocomplete>
                </ng-container>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>


        <!-- matSort -->

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15 text-center" style="padding-left: 31px !important">Phone no</th>
                <td mat-cell *matCellDef="let row" class="w-15">
                    <span class="text-gray-900 tiny-letter-spacing">{{ row['phone']  }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="names">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30 text-center" style="padding-left: 31px !important">Names</th>
                <td mat-cell *matCellDef="let row" class="w-30">
                    <span class="text-gray-900 tiny-letter-spacing">{{ row['accountDetails']['lastName']+" "+row['accountDetails']['firstName']  }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="uname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-21"> Username</th>
                <td mat-cell *matCellDef="let row" class="w-21">
                    <span class="text-gray-900 tiny-letter-spacing">{{ row['customerCare']['staffId'] | defaultText}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="who">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-21">Added by</th>
                <td mat-cell *matCellDef="let row" class="w-21">
                    <span class="text-gray-900 tiny-letter-spacing">{{ row['customerCare']['who'] | defaultText}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-21">Joined on</th>
                <td mat-cell *matCellDef="let row" class="w-21">
                    <span class="text-gray-900 tiny-letter-spacing">{{ row['customerCare']['timeStamp'] |  date: "dd MMM yyyy" }} </span>
                </td>
            </ng-container>


            <ng-container matColumnDef="cta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">Actions</th>
                <td mat-cell *matCellDef="let row" class="w-25">
                    <button mat-flat-button style="color: #0097b9; letter-spacing: 0.025em; padding-right: 0.3rem" class="rounded-full tiny-letter-spacing" aria-label="Example icon button with a heart icon">EXPAND
                        <mat-icon style="font-size: 15px !important; vertical-align: bottom">open_in_full</mat-icon>
                    </button>
                </td>
            </ng-container>


            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" style="margin-left: -1px !important; padding-left: 0 !important" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="example-element-description w-full">
                            <h3 class="text-gray-900"> More Agent details</h3>
                            <br/>
                            <p>
                                <span class="example-element-description-attribution uppercase">Role:</span>
                                <span class="ml-2 text-gray-800">{{ element['customerCare']['position'] }}</span>
                            </p>
                            <p>
                                <span class="example-element-description-attribution uppercase">Nin:</span>
                                <span class="ml-2 text-gray-800">{{ element['accountDetails']['nin'] }}</span>
                            </p>
                            <p>
                                <span class="example-element-description-attribution uppercase">Gender:</span>
                                <span class="ml-2 text-gray-800">{{ element['accountDetails']['gender'] }}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution uppercase">Country:</span>
                                <span class="ml-2 text-gray-800">{{ element["country"] }}</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution uppercase">Received tickets:</span>
                                <!--            <span class="ml-2 text-gray-800">{{element["nextOfKeen"] | date }}</span>-->
                                <span class="ml-2 text-gray-800">5</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution uppercase">TRANSFERRED tickets:</span>
                                <!--            <span class="ml-2 text-gray-800">{{element["customerNo"] | defaultText }}</span>-->
                                <span class="ml-2 text-gray-800">2</span>
                            </p>

                            <p>
                                <span class="example-element-description-attribution uppercase" style="font-size:14px;">Pending tickets:</span>
                                <span class="ml-2 text-gray-800">2</span>
                            </p>
                            <p>
                                <span class="example-element-description-attribution uppercase" style="font-size:14px;">Resolved tickets:</span>
                                <span class="ml-2 text-gray-800">1</span>
                            </p>

                            <!--<table style="margin-left: -3px;">
                                <tr>
                                    <th class="mini-table-header text-gray-700">
                                        <span style="font-weight: 500 !important;">Field officer id</span>
                                    </th>

                                    <th class="mini-table-header text-gray-700">
                                        <span style="font-weight: 500 !important;">Role</span>
                                    </th>

                                    <th class="mini-table-header text-gray-700">
                                        <span style="font-weight: 500 !important;">Region</span>
                                    </th>

                                    <th class="mini-table-header text-gray-700">
                                        <span style="font-weight: 500 !important;">Who</span>
                                    </th>

                                </tr>
                                <tr>
                                    <td class="text-gray-900">
&lt;!&ndash;                                        {{element["fieldOfficer"]["fieldOfficerId"] | defaultText}}&ndash;&gt;
                                    </td>
                                    <td class="text-gray-900">
&lt;!&ndash;                                        {{element["fieldOfficer"]["role"] | defaultText}}&ndash;&gt;
                                    </td>
                                    <td class="text-gray-900">
&lt;!&ndash;                                        {{element["fieldOfficer"]["region"] | defaultText}}&ndash;&gt;
                                    </td>
                                    <td class="text-gray-900">
&lt;!&ndash;                                        {{element["fieldOfficer"]["who"] | defaultText}}&ndash;&gt;
                                    </td>
                                </tr>
                            </table>-->
                            <br/>
                        </div>

                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

        <p class="text-center text-gray-800 pt-3" *ngIf="resultsLength == 0">
            No Agents found...!
        </p>

        <mat-paginator [length]="resultsLength" [pageSize]="5"></mat-paginator>
    </div>

</div>
