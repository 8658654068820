<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <!--    <div class="example-rate-limit-reached text-gray-800"  >-->
    <!--      Oops there are results, check your network, refresh browser & try again-->
    <!--    </div>-->
</div>

<form class="align-menu-right" [formGroup]="categoryMenuGroup">
    <mat-form-field class="category-form" [floatLabel]="'never'">
        <mat-label>Select category</mat-label>
        <mat-select formControlName="category">
            <mat-option *ngFor="let option of filterOptions" [value]="option">
                {{ option }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>

<!-- matSort -->

<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="complaintId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15"> Customer no</th>
        <td mat-cell *matCellDef="let row" class="w-15"> <!--| normalizeNumber-->
            <span class="text-gray-800 tiny-letter-spacing">{{ row.customerNo }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30" style="text-align: center !important;">
            Customer name
        </th>
        <td mat-cell *matCellDef="let row" class="w-30 text-center">
            <span class="text-gray-900 tiny-letter-spacing">{{ row.customerName | uppercase}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-21">Time issued</th>
        <td mat-cell *matCellDef="let row" class="w-21">
            <span class="text-gray-900 tiny-letter-spacing">{{ row.time |  date: "dd MMM yyyy - hh:mm a" }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="isClosed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">Status</th>
        <td mat-cell *matCellDef="let row" class="w-15 text-center">
            <!--            <div [className]="row.status ? 'status-button text-gray-800 success-pill' : 'status-button text-gray-800 danger-pill'">{{row.status ? 'passed' : 'failed'}}-->
            <!--                <mat-icon class="text-gray-800" style="font-size: 15px;position: absolute; top: 19%; left: 5%;">{{row.status ? 'check_circlel' : 'cancel'}}</mat-icon>-->
            <span class="text-gray-900 tiny-letter-spacing"> {{ row.isClosed.isClosedStatus ? "CLOSED" : "OPEN"  }} </span>
            <!--            </div>-->
        </td>
    </ng-container>

    <ng-container matColumnDef="who">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">On who's desk</th>
        <td mat-cell *matCellDef="let row" class="w-15">
            <span class="text-gray-900 tiny-letter-spacing">{{ row.attachment[0].t_name  }} </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="ctype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">TYPE</th>
        <td mat-cell *matCellDef="let row" class="w-15">
            <span class="text-gray-900 tiny-letter-spacing">{{ row.category }} </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="cta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25" style="text-align: center !important;">
            Actions
        </th>
        <td mat-cell *matCellDef="let row" class="w-25 text-right">
            <button mat-flat-button (click)="openDialog(row)"
                    style="color: #0097b9;padding-right: 0.3rem;font-weight: 600;"
                    class="rounded-full tiny-letter-spacing" aria-label="Example icon button with a heart icon" *ngIf="!row.isClosed.isClosedStatus">
                RESOLVE...&#9998;
            </button>
            <span *ngIf="row.isClosed.isClosedStatus" style="text-align: center; color: #0097b9;padding-right: 0.3rem;font-weight: 600;" >
                ...&#10003;
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" style="margin-left: -1px !important; padding-left: 0 !important"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="example-element-description w-full">
                    <h3 class="text-gray-900"> More details... </h3>
                    <br/>
                    <p>
                        <span class="example-element-description-attribution">Category:</span>
                        <span class="ml-2 text-gray-800">{{ element["category"] }}</span>
                    </p>

                    <!--                    <p>-->
                    <!--                        <span class="example-element-description-attribution">Time issued:</span>-->
                    <!--                        <span class="ml-2 text-gray-800">{{element["time"] | date}}</span>-->
                    <!--                    </p>-->

                    <p *ngIf=" element['task'] !== 'UNDEFINED' ">
                        <span class="example-element-description-attribution">Task:</span>
                        <span class="ml-2 text-gray-800">{{ element["task"] }}</span>
                    </p>

                    <p>
                        <span class="example-element-description-attribution">Complaint Id:</span>
                        <span class="ml-2 text-gray-800">{{ element["complaintId"] }}</span>
                    </p>

                    <p *ngIf="element['transactionId']">
                        <span class="example-element-description-attribution">Transaction Id:</span>
                        <span class="ml-2 text-gray-800">{{element["transactionId"] ? element["transactionId"] : 'NOT AVAILABLE'}}</span>
                    </p>

                    <p *ngIf="element['complaint']">
                        <span class="example-element-description-attribution">Description:</span>
                        <span class="ml-2 text-gray-800">{{element["complaint"]}}</span>
                    </p>
                    <p style="float: right; margin-right: 40px;" *ngIf="!element['isClosed'].isClosedStatus">
                        <span>
                            <button mat-raised-button (click)="addRefer(element)" class="rounded-full text-gray-900 tiny-letter-spacing">REASSIGN</button>
                        </span>
                    </p>
<!--START-->

                    <table mat-table [dataSource]="passATT(element['attachment'])" multiTemplateDataRows style="background: #d7d8d9; font-style: italic;">
                        <ng-container matColumnDef="by">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15" >
                                Acted on By
                            </th>
                            <td mat-cell *matCellDef="let row" class="w-15">
                                <span class="text-gray-900 tiny-letter-spacing">{{ row.w_name}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15"> Action</th>
                            <td mat-cell *matCellDef="let row" class="w-15"> <!--| normalizeNumber-->
                                <span class="text-gray-800 tiny-letter-spacing">{{ getDisplayText(row.complaintStatus) }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="from">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">From</th>
                            <td mat-cell *matCellDef="let row" class="w-15">
                                <span class="text-gray-900 tiny-letter-spacing">{{ row.f_name  }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">To</th>
                            <td mat-cell *matCellDef="let row" class="w-15 text-center">
                                <!--            <div [className]="row.status ? 'status-button text-gray-800 success-pill' : 'status-button text-gray-800 danger-pill'">{{row.status ? 'passed' : 'failed'}}-->
                                <!--                <mat-icon class="text-gray-800" style="font-size: 15px;position: absolute; top: 19%; left: 5%;">{{row.status ? 'check_circlel' : 'cancel'}}</mat-icon>-->
                                <span class="text-gray-900 tiny-letter-spacing"> {{ row.t_name  }} </span>
                                <!--            </div>-->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="when">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-21">when</th>
                            <td mat-cell *matCellDef="let row" class="w-21">
                                <span class="text-gray-900 tiny-letter-spacing">{{ row.attachTime |  date: "dd MMM yyyy - hh:mm a" }} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Reason">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-30">Reason</th>
                            <td mat-cell *matCellDef="let row" class="w-30">
                                <span class="text-gray-900 tiny-letter-spacing">{{ row.reason }} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="cta">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25" style="text-align: center !important;">
                                Actions
                            </th>
                            <td mat-cell *matCellDef="let row" class="w-25">
                                <button mat-flat-button (click)="openDialog(row)"
                                        style="color: #0097b9;padding-right: 0.3rem;font-weight: 600;"
                                        class="rounded-full tiny-letter-spacing" aria-label="Example icon button with a heart icon">
                                    RESOLVE...&#9998;
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetailAtt">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsAtt.length">
                                <div class="example-element-detail" style="margin-left: -1px !important; padding-left: 0 !important"
                                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAtt"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumnsAtt" class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element ? null : element"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetailAtt']" class="example-detail-row"></tr>
                    </table>

                    <div class="comment-thread" style="background: #d7d8d9;width: 100%; float: right">
                        <!-- Comment 1 start -->
                        <h3 style="margin-top: 20px"><strong>COMMENTS:</strong></h3>
                        <div class="comment" id="comment-1" *ngFor="let content of element['comment']">
                            <div class="comment-heading">
                                <div class="comment-info">
                                    <a href="#" class="comment-author">Posted by: {{content.c_name}}</a>
                                    <p class="m-0">
                                        {{ content.timePoint |  date: "dd MMM yyyy - hh:mm a" }}
                                    </p>
                                </div>
                            </div>

                            <div class="comment-body">
                                <p>
                                    {{content.comments}}
                                </p>
<!--                                <span *ngIf="content.dueTime"  style="border: #00cfe9; border-radius:3rem; border-style: solid; padding:5px">Its deadline set for <i>{{ content.dueTime |  date: "dd MMM yyyy - hh:mm a" }}</i></span>-->
                            </div>

                        </div>
                        <span style="float: right;">
                            <button mat-raised-button (click)="addComments(element)" class="rounded-full text-gray-900 tiny-letter-spacing">ADD COMMENT</button>
                        </span>
                        <!-- Comment 1 end -->
                    </div>
<!--STOP-->
                </div>

              <!--  <p style="padding-top: 11% !important" class="relative text-center">

                    <button mat-raised-button (click)="addComments(element)"
                            class="rounded-full text-gray-900 tiny-letter-spacing" style="margin: 20% 0 20% 0">LEAVE COMMENT
                    </button>
                </p>-->

            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<p class="text-center text-gray-800 pt-3" *ngIf="isRateLimitReached">
    No logged tickets yet...
</p>

<mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent = $event" aria-label="Select page">
