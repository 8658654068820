<ng-container *ngIf="!data.isLoading">
  <h3 mat-dialog-title class="text-gray-800 tiny-letter-spacing">Query tickets by <span class="lowercase">{{data.operation}}</span></h3>
  <br/>
  <div mat-dialog-content *ngIf="data.operation === 'TIME'">
    <form [formGroup]="ticketsQueryGroup" class="flex">
      <div class="w-full">
          <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="startTime">
                <input matEndDate placeholder="End date" formControlName="endTime">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-content *ngIf="data.operation === 'STATUS'">
      <form [formGroup]="ticketsQueryGroup" class="flex">
        <div class="w-full">
          <mat-checkbox
          formControlName="isClosedStatus">
         <h3 class="text-gray-800" style="display:inline;">Check box if searching for only closed tickets</h3>
        </mat-checkbox>
        </div>
      </form>
    </div>

  <div mat-dialog-content *ngIf="data.operation === 'CATEGORY'">
      <form [formGroup]="ticketsQueryGroup" class="flex">
          <div class="w-full">
              <mat-form-field appearance="fill">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="category">
                    <mat-option *ngFor="let category of categories" [value]="category">
                      {{category}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          </div>
        </form>
  </div>

  <div  *ngIf="data.operation === 'CATEGORY_TIME'">
      <form [formGroup]="ticketsQueryGroup" class="flex flex-col">
          <div class="w-full">
              <mat-form-field appearance="fill">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="category">
                    <mat-option *ngFor="let category of categories" [value]="category">
                      {{category}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          </div>

          <div class="w-full">
              <mat-form-field appearance="fill">
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" formControlName="startTime">
                    <input matEndDate placeholder="End date" formControlName="endTime">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
          </div>
        </form>
  </div>
  <div  *ngIf="data.operation === 'STATUS_TIME'">
     
    <form class="flex flex-col" [formGroup]="ticketsQueryGroup">
        <div class="w-full">
          <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="startTime">
                <input matEndDate placeholder="End date" formControlName="endTime">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>

        <div>
            <mat-checkbox
            formControlName="isClosedStatus">
           <span class="text-gray-800">Check box if searching for only closed tickets</span>
          </mat-checkbox>
        </div>
    </form>
  </div>

  <div *ngIf="data.operation === 'CATEGORY_STATUS_TIME'">
     <form [formGroup]="ticketsQueryGroup" class="flex flex-col">
          <div class="w-full">
              <mat-form-field appearance="fill">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="category">
                    <mat-option *ngFor="let category of categories" [value]="category">
                      {{category}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          </div>

          <div class="w-full">
              <mat-form-field appearance="fill">
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" formControlName="startTime">
                    <input matEndDate placeholder="End date" formControlName="endTime">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
          </div>
          <div>
              <mat-checkbox
              formControlName="isClosedStatus">
             <span class="text-gray-800">Check box if searching for only closed tickets</span>
            </mat-checkbox>
          </div>
        </form>
  </div>

  <br/>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" class="rounded-full uppercase tiny-letter-spacing" 
  style="color: #fff !important;"
  (click)="submitQuery()"
 
  
  cdkFocusInitial>Get results</button>

  <button mat-button 
   class="rounded-full uppercase" 
   [mat-dialog-close]="true" 
  cdkFocusInitial>Cancel</button>

</div>
</ng-container>

<ng-container *ngIf="data.isLoading">
  <div class="py-8">
    <mat-progress-bar></mat-progress-bar>
    <p class="text-gray-800 text-center text-base">Processing please wait</p>
  </div>
</ng-container>