<div class="home-body pt-3">
    <div class="flex flex-row">
        <div class="flex-1" *ngFor="let action of actions">
        <mat-card class="dashboard-card" >
            <div class="indicator-block flex-center" [style.background]="action.color">
<!--                <mat-icon  aria-hidden="false" aria-label="icon" class="icon-indicator">{{action.icon}}</mat-icon>-->
                <img style="max-width: 24px;max-height: 24px;" [src]='getURL(action.icon)' />
            </div>
           
            <br/>
            <!-- [style.color]="router.url == action.url ? 'active': 'inactive'" -->
<!--          <a [routerLink]="action.url" class="cta-action"-->
          <a [routerLink]="action.url" class="btn-out cta-action"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{exact: true}"
          >{{action.action}} </a>

        </mat-card>
        </div>
            
    </div>
</div>
<div class="home-body">
    <router-outlet></router-outlet>
</div>

