/**
 * Created by chrisp on 08/10/2017.
 */
 import {Injectable} from '@angular/core';
 import {
     HttpEvent,
     HttpInterceptor,
     HttpHandler,
     HttpRequest,
     HttpResponse,
     HttpErrorResponse, HttpHeaders
 } from '@angular/common/http';
 import {Router, ActivatedRoute} from '@angular/router';
 import {environment} from '../../environments/environment';
 import {any} from 'codelyzer/util/function';
 import {map, tap} from 'rxjs/operators';
 import {Observable, throwError} from "rxjs";
 import { WebService } from '../web-services/web.service';
 import { WindowService } from '../web-services/window.service';
 
 
 @Injectable()
 export class CpAuthInterceptor implements HttpInterceptor {
     key: any = 'locker';
     returnUrl: any;
     userId: any;
     isLogin = 'NONE';
 
 
     constructor(private router: Router, private route: ActivatedRoute, private service: WebService, private enc: WindowService) {
         this.key = 'locker';
     }
 
     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         const _request = this.handleBodyIn(request);
         return next.handle(_request).pipe(map(ev => {
                 if (ev instanceof HttpResponse) {
                     let currentUser: any = JSON.parse(localStorage.getItem('currentUser') || '{}');
                     let id: any = localStorage.getItem('account_id').toString().trim();
                     console.log("ID", id);
                     const timeLock = ev.headers.get('timestamp').trim();
                     id = id.length < 11 ? localStorage.getItem('account_id').toString().trim() : id;
                     const key = this.isLogin === 'AUTH' ? timeLock.substring(8).trim() + id.substring(8).trim() + timeLock.substr(0, 7).trim() + id.substr(0, 7).trim() : (this.isLogin === 'REG') ? timeLock.substring(8).trim() + timeLock.substr(0, 7).trim() : currentUser.token.trim() + timeLock.substring(8).trim() + id.substring(8).trim() + timeLock.substr(0, 7).trim() + id.substr(0, 7).trim();
                     const sk_open = this.enc.getKey(key).trim();
                     console.log("KEY", ev);
                     console.log(ev.headers);
                     if (ev.body && ev.status === 200) {
                         // if ((!currentUser._1 || currentUser._2 || currentUser._3)) {
                         this.service.notificationsSubject.next({status: true, message: 178});
                         const result = this.enc.decrypt(ev.body.toString().trim(), sk_open);
                         console.log('Result.', result);
                         let data = {status: false, message: any, access_token: ''};
                         try {
                             data = (result) ? JSON.parse(result.toString()) : ' ';
                         } catch (e) {
                             // console.log('Wrong Json Result.', e);
                         }
                         if (data.status && data.message && data.access_token) {
                             currentUser = {
                                 ...data,
                                 'token': data.access_token.trim(),
                                 'lock': false,
                                 'access_token': '',
                                 'id': 'id'
                             };
                             localStorage.setItem('currentUser', JSON.stringify(currentUser));
                             localStorage.setItem('account_type', currentUser.message.account.trim());
                             localStorage.setItem('account_id', currentUser.message.phone.trim());
                             localStorage.setItem('facility', (currentUser.message.account === 'BUSINESS' ? currentUser.message.facilityMatch.indexOf('ALL') > -1 : false).toString());
                             this.setAccount(currentUser.message.phone.trim());
                             ev = ev.clone({body: data, headers: ev.headers.set('Content-Type', 'application/json')});
                             const action: any = localStorage.getItem('action')
                             console.log(action)
                             this.setRoutes(currentUser.message.account);
                             return ev;
                         } else {
                             ev = ev.clone({body: data, headers: ev.headers.set('Content-Type', 'application/json')});
                             return ev;
                         }
                     } else {
                         return ev;
                     }
                 } else {
                     return ev;
                 }
             }, (err: any) => {
                 if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
                     this.service.lockscreen();
                 } else if (err instanceof HttpErrorResponse && err.status === 400) {
                     this.service.logout_data();
                     this.router.navigate(['/login']);
                 } else {
                     // console.log('EEEEEE', err);
                 }
                 return err;
             }
         ))
     }
 
     private handleError(err: any) {
         let errorMessage: string;
         if (err.status === 401) {
             this.service.lockscreen();
             errorMessage = `An error occurred: ${err.error.message}`;
             return err.status;
         } else {
             errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
         }
         return throwError(errorMessage);
     }
 
     setAccount(account: any) {
         localStorage.setItem('account_id', account.trim());
     }
 
     setRoutes(acc_type: string) {
         const preRegister = '/preFacility';
         const user = '';
         const business = '/business';
         const facility = '/facility';
         this.returnUrl = (acc_type.trim() === 'USER') ? (user) : (acc_type.trim() === 'FACILITY') ? (facility) : (acc_type.trim() === 'preFacility') ? (preRegister) : (acc_type.trim() === 'BUSINESS') ? (business) : user;
         this.router.navigate([this.returnUrl]);
     }
 
     handleBodyIn(req: HttpRequest<any>): HttpRequest<any> {
         let token = '', id = '', body = '', timeStamp = '';
         const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
         let sk_close: string;
         timeStamp = (new Date().getTime()).toString().trim();
         if (req.method.toLowerCase() === 'post' && req.url.toLowerCase() === environment.prodURL + '/auth') {
             // console.log('MSISDN', req.headers);
             req.headers.get('MSISDN').trim()
             req.headers.get('msisdn').trim()
             localStorage.setItem('currentUser', JSON.stringify({...currentUser}));
             localStorage.setItem('account_id', req.headers.get('msisdn').trim());
             sk_close = this.enc.getKey(timeStamp.substring(6).trim() + timeStamp.substring(0, 5).trim() + '256').trim();
             token = this.enc.encrypt(req.headers.get('Authorization').trim(), sk_close).trim();
             this.isLogin = 'AUTH';
         } else if (req.method.toLowerCase() === 'put' && req.url.toLowerCase() === environment.prodURL + '/auth') {
             localStorage.setItem('account_id', req.headers.get('MSISDN').trim());
             sk_close = this.enc.getKey(timeStamp.substring(6).trim() + timeStamp.substring(0, 5).trim() + '256').trim();
             console.log("Body", req.body.valueOf());
             body = (req.body) ? this.enc.encrypt(JSON.stringify(req.body.valueOf()), sk_close) : req.body
             this.isLogin = 'REG';
         } else if (currentUser && currentUser.token) {
             this.isLogin = 'NONE';
             id = localStorage.getItem('account_id').toString().trim();
             token = currentUser.token.trim();
             id = id.length < 9 ? localStorage.getItem('account_id').toString().trim() : id;
             if (!id) {
                 return null;
             }
             sk_close = this.enc.getKey(token + timeStamp.substring(6).trim() + id.substring(6).trim() + timeStamp.substring(0, 5).trim() + id.substring(0, 5).trim()).trim();
             body = (req.body) ? this.enc.encrypt(JSON.stringify(req.body.valueOf()), sk_close) : req.body
         }
         req = req.clone({headers: new HttpHeaders({'Accept': '*/*'}).set('X-Target-Environment', 'CARE').set('TimeStamp', timeStamp).set('Authorization', token).delete('MSISDN')})
             .clone({responseType: 'text'}).clone({body: body});

         return req;
     }
 
 }
