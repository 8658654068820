import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-ticketquerydialog',
  templateUrl: './ticketquerydialog.component.html',
  styleUrls: ['./ticketquerydialog.component.scss']
})
export class TicketquerydialogComponent implements OnInit {
  ticketsQueryGroup: FormGroup;
  loading: boolean = false;
  categories: string[] = ['TRANSACTION_ISSUES'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: {operation: string, isLoading: boolean}, 
  private _fb: FormBuilder, private dialog: MatDialog) {
      this.ticketsQueryGroup = this._fb.group({
        startTime: [''],
        endTime: [''],
        category: ['TRANSACTION_ISSUES'],
        isClosedStatus: [false]
      });
   }

  ngOnInit(): void {
    // console.log('my dialog isLoading', this.data.isLoading)
  }

  queryTickets(): void{
    
  }


  submitQuery(): void {
    const ticketsGroup = this.ticketsQueryGroup.getRawValue();
    const payload = {operation: this.data.operation,
      startTime: `${new Date(ticketsGroup['startTime']).getTime()}`,
      endTime: `${new Date(ticketsGroup['endTime']).getTime()}`,
      category: ticketsGroup['category'],
      isClosedStatus: ticketsGroup['isClosedStatus']
    };
    // this.ticketService.sendMessage(payload);
    this.dialog.closeAll();
  }
}
