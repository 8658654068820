import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllTickets } from 'src/app/models/models';
import { PhoneNumber, WebService } from 'src/app/web-services/web.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-referdialog',
  templateUrl: './referdialog.component.html',
  styleUrls: ['./referdialog.component.scss']
})
export class ReferdialogComponent implements OnInit {
  loading: boolean = false;
  referGroup: FormGroup;
  isSpinning: boolean = false;
  role: string;
  userTo: string;
  userProfile: { name: string; nin: string; _id: string, uname: string, position: string, co: string };
  searchTeamContactCtrl = new FormControl();
  filteredNumbers: any;
  isLoading = false;
  errorMsg: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: AllTickets, private svc: WebService,
   private _fb: FormBuilder, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.referGroup = this._fb.group({
      who: ['', [Validators.required]],
      reason: ['', [Validators.required]],
      attachTime: [0]
    });


    this.searchTeamContactCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredNumbers = [];
          this.isLoading = true;
        }),
        switchMap(value => {
          let phone = new PhoneNumber();
          phone.line = value;
          return this.svc.apiCall({
            "task":"GET_CUSTOMER_CARE",
            "customerNo": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
            "accountId": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
            "customerName" : ((/^0?(256)?((7([8|7|6]))|39)([0-9]{7})$/.test(value+"")) ? phone.e164 : value+"") ,
            "pagination": { 
                page: 0, 
                max: 100
             }
        })
            .pipe(
              finalize(() => {
                this.isLoading = false
              }),
            )
        }
        )
      )
      .subscribe((data: any) => {
        console.log('xhcz', data);
        if (data['message'] == undefined) {
          this.errorMsg = data['Error'];
          this.filteredNumbers = [];
        } else {
          this.errorMsg = "";
          this.filteredNumbers = data['message'];
          console.log(this.filteredNumbers);
        }
      }, err => this.svc.handleError(err));
      
  }

  onSelectingCare(data: any){
    // @ts-ignore
    this.referGroup.controls['who'].setValue( data.customerCare.position);
    this.userTo = data.phone;
  }


  referTicket(from:any): void{
    console.log("from", from);
    const date = new Date();
    const referGroup = this.referGroup.getRawValue();
    const hour = Number(`${referGroup['attachTime']}`.substring(0,2));
    const minutes = Number(`${referGroup['attachTime']}`.substring(3,5));
    const formatedDate = new Date(date.getFullYear(), 
    date.getMonth(), date.getDay(), hour, minutes, 0, 0).getTime();
    const phone = new PhoneNumber();
    phone.line = referGroup['who'];
    console.log("Attach Time", referGroup['attachTime']);
    this.loading = true;
    this.svc.apiCall({
      task: "REFER",
      "customerNo": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
      "accountId": `FRI:${this.userProfile._id}@clinicpesa.public.user.sp1/SP`,
      complaintId: this.data.complaintId,
      attachment: [
        {
          from: from,
          to: this.userTo,
          who: this.userProfile._id,
          reason: referGroup['reason'],
          attachDue: referGroup['attachTime'],
          complaintStatus: "REFERRED",
        }
      ]
    }).subscribe((data: any) => {
    if(data.status){
      this.loading = false;
      this.svc.notify('success', `Successfully refered ticket to ${phone.e164}`, 2);
      this.dialog.closeAll();
    }else{
      this.loading = false;
      this.svc.notify('error', data.message, 2);
    }
    }, error => {
      this.loading = false;
      this.svc.handleError(error);
    });
  }

  // displayFn(user: User): string {
  //   return user && user.name ? user.name : '';
  // }

  getUserProfile(): void {
    try {
      const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
      if (!currentUser) {
        throw new Error('User does not exist');
      }

      this.userProfile = {
        _id: JSON.parse(localStorage.getItem('account_id')),
        name: currentUser['message']['firstName'] + ' ' + currentUser['message']['lastName'],
        nin: currentUser['message']['nin'],
        uname: currentUser['message']['customerCare']['staffId'],
        position: currentUser['message']['customerCare']['position'],
        co: currentUser['message']['customerCare']['company']
      };
      console.log('my hoe bitch', this.userProfile)
    } catch (e) {
      console.log(e);
    }

  }



}
