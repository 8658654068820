import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {HomeComponent} from './pages/home/home.component';
import {MatCardModule} from '@angular/material/card';
import {TeamsComponent} from './pages/teams/teams.component';
import {AccountsComponent} from './pages/accounts/accounts.component';
import {TransactionsComponent} from './pages/transactions/transactions.component';
import {TicketsComponent} from './pages/tickets/tickets.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {CreateTicketComponent} from './pages/create-ticket/create-ticket.component';
import {MatDividerModule} from '@angular/material/divider';
import {DatesAgoPipe} from './pipes/dates-ago.pipe';
import {FilteringPipe} from './pipes/filtering.pipe';
import {WindowService} from './web-services/window.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {CpAuthInterceptor} from './interceptors/clinicPesaauth.interceptor';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxSpinnerModule} from "ngx-spinner";
import {NgxIndexedDBModule, DBConfig} from 'ngx-indexed-db';
import {ResolvedialogComponent} from './sharables/resolvedialog/resolvedialog/resolvedialog.component';
import {MatChipsModule} from '@angular/material/chips';
//import { CommentsComponent } from './sharables/commentsdialog/comments/comments.component';
import {ReferdialogComponent} from './pages/referdialog/referdialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ErrorComponent} from './pages/error/error.component';
import {ReactiveFormsModule} from '@angular/forms';
//import { TicketquerydialogComponent } from './sharables/ticketquerydialog/ticketquerydialog/ticketquerydialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TeamsdialogComponent} from './pages/teamsdialog/teamsdialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CommentsdialogModule} from './sharables/commentsdialog/commentsdialog.module';
import {TicketquerydialogModule} from './sharables/ticketquerydialog/ticketquerydialog.module';
import {ResolvedialogModule} from './sharables/resolvedialog/resolvedialog.module';
import {DatatableModule} from './sharables/datatable/datatable.module';
import {SetDefaultText} from './pipes/defaultText.pipe';
import {AuthLayoutComponent} from "./layout/auth/auth-layout.component";
import {SetUsernameComponent} from "./pages/set-username/set-username.component";


const dbConfig: DBConfig = {
    name: 'MyDb',
    version: 1,
    objectStoresMeta: [{
        store: 'Tickets',
        storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: []
    }]
};


@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        SidebarComponent,
        HomeComponent,
        TeamsComponent,
        AccountsComponent,
        TransactionsComponent,
        TicketsComponent,
        CreateTicketComponent,
        SetUsernameComponent,
        DatesAgoPipe,
        FilteringPipe,
        ReferdialogComponent,
        ErrorComponent,
        TeamsdialogComponent,
        SetDefaultText
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatTabsModule,
        MatExpansionModule,
        MatInputModule,
        MatPaginatorModule,
        MatTableModule,
        MatDatepickerModule,
        MatSelectModule,
        MatDialogModule,
        MatDividerModule,
        MatSnackBarModule,
        NgxSpinnerModule,
        HttpClientModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        MatChipsModule,
        MatTooltipModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        TicketquerydialogModule, ResolvedialogModule, CommentsdialogModule, DatatableModule
    ],
    providers: [
        FilteringPipe,
        Location,
        // WebService,
        WindowService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CpAuthInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
