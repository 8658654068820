<ng-container *ngIf="!loading">
    <h3 mat-dialog-title style="margin-bottom: 1px !important; color: rgba(55, 65, 81, var(--tw-text-opacity));font-weight: 400;">Add Username</h3>
    <br/>
    <div mat-dialog-content>
        <form>
            <div class="flex flex-row">
                <div class="w-full">
                    <mat-form-field appearance="fill">
                        <mat-label>Enter preferred username...</mat-label>
                        <input type="text" placeholder="" required matInput [formControl]="u_name_ctl"/>
                        <mat-progress-spinner *ngIf="formSpinner" matSuffix mode="indeterminate" diameter="16"></mat-progress-spinner>
                    </mat-form-field>
                </div>
            </div>
        </form>


        <div class="flex flex-col" *ngIf="isUnameResp">
            <small style="color:red;padding-top:5px;padding-bottom: 5px;" *ngIf="!availability">
                <strong style="color: #c0272d"> Username already taken. Please continue editing... &#9998; </strong>
            </small>

            <small style="color:red;padding-top:5px;padding-bottom: 5px;" *ngIf="availability">
                <strong style="color: #00bcd4"> Username available for user... &#10003; </strong>
            </small>
        </div>


    </div>


    <mat-dialog-actions align="end" *ngIf="availability">
        <button mat-button mat-dialog-close class="rounded-full">cancel</button>
        <button mat-raised-button (click)="createUName()" class="btn-out cta-action" cdkFocusInitial>Submit</button>
    </mat-dialog-actions>
</ng-container>

<div class="py-8" *ngIf="loading">
    <mat-progress-bar></mat-progress-bar>
    <p class="text-gray-800 text-center text-base">Processing please wait...</p>
</div>
